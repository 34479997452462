import React, { useState } from "react";
import {
  Grid,
  Select,
  MenuItem,
} from "@material-ui/core";
// import { useTheme } from "@material-ui/styles";


// styles
import useStyles from "./styles";

// components
import Widget from "../../components/Widget";
import PageTitle from "../../components/PageTitle";

import Box from '@material-ui/core/Box';
import { Button } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

import { useUserDispatch, useUserState } from "../../context/UserContext";
import { useConfigState } from "../../context/ConfigProvider";

// for test
// import TokenChecker from "../../context/TokenChecker"

export default function Dashboard(props) {
  // global
  const userDispatch = useUserDispatch();
  const userState = useUserState();
  const { allConfigs } = useConfigState();

  // const hasProviderId = !!userState.providerId;

  const classes = useStyles();
  // const theme = useTheme();

  // local
  const [providerIdField, setProviderIdField] = useState(userState.providerId ?? '')

  const handleChange = (event) => {
    setProviderIdField(event.target.value);
  };

  return (
    <>
      <PageTitle title="Työpöytä" />

      {/* for testing auth */}
      {/* <TokenChecker /> */}

      <Grid container spacing={4}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Widget
            title="Kohteen valinta"
            upperTitle
            bodyClass={classes.fullHeightBody}
            className={classes.card}
            disableWidgetMenu={true}
          >
            <div>
              <FormControl className={classes.formControl}>
                <Box margin={1}>
                  <InputLabel id="provider-label">Valitse kohde</InputLabel>
                </Box>
                <Box margin={1}>
                  <Select
                    labelId="provider-label"
                    id="provider-select"
                    value={providerIdField}
                    onChange={handleChange}
                  >
                    {allConfigs.map(c =>
                      <MenuItem key={c.pid} value={c.pid}>{c.name}</MenuItem>
                    )}
                  </Select>
                </Box>
                <Box margin={1}>
                  <Button variant="contained" color="primary" onClick={() => userDispatch({ type: 'SET_PROVIDER_ID', providerId: providerIdField })}>Vahvista valinta</Button>
                </Box>
              </FormControl>
            </div>
          </Widget>
        </Grid>
      </Grid>

      {/* {hasProviderId && <Grid container spacing={4}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Widget
            title="Viestit 2020"
            upperTitle
            bodyClass={classes.fullHeightBody}
            className={classes.card}
          >
            <div className={classes.visitsNumberContainer}>
              <Chart />
            </div>
          </Widget>
        </Grid>
      </Grid>} */}
    </>
  );
}

