import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import DialogContent from '@material-ui/core/DialogContent';

import MUIDataTable from 'mui-datatables';
import MultilineDisplay from '../../../components/MultilineDisplay/MultilineDisplay';
import Legend from './Legend';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DetailsDialog(props) {
  const classes = useStyles();
  const { open, setOpen, selected, customers, type } = props;


  const handleClose = () => {
    setOpen(false);
  };

  const findCustomerInfo = (value) => {
    if (type === 'email') {
      const c = customers.find(c => c.email === value)
      return c ? `${c.name}` : `(arvoa ${value} ei löytynyt asiakkaiden sähköposti kentästä)`
    } else {
      const c = customers.find(c => c.mobile === value)
      return c ? `${c.name}` : `(arvoa ${value} ei löytynyt asiakkaiden puhelin kentästä)`
    }
  }

  // customers table columns
  const columns = [
    {
      name: "recipient",
      label: "Vastaanottaja",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          value && <MultilineDisplay>{`${value}\n${findCustomerInfo(value)}`}</MultilineDisplay>
        )
      }
    },
    {
      name: "status",
      label: "Tila",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          //TODO: make color codes better for different statuses
          // value && <span className={classes[value.toLowerCase()]}>{value}</span>
          value && <Legend status={value} />
        )
      }
    },
    {
      name: "timestamp",
      label: "Aikaleima",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          value && new Date(value).toLocaleString()
        )
      }
    },
  ];

  return (
    <div>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} title="Sulje" aria-label="Sulje">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              <span>Viestin {selected.id} tila</span>
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
              Sulje
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <MUIDataTable
            // title="Viestin vastaanottajat"
            data={selected.statuses}
            columns={columns}
            options={{
              filter: true,
              download: false,
              print: false,
              responsive: 'standard',
              selectableRows: 'none',
            }}
          />
          {/* TODO: make status legend better*/}
          {type === 'email' && <>
            {/* email statuses: https://sendgrid.com/blog/the-nine-events-of-email/ */}
            <Legend status="bounce" legend="viestiä ei voitu toimittaa, sähköpostiosoite ei kelvannut" />
            <Legend status="deferred" legend="viestiä ei voitu toimittaa välittömästi, toimitusta yritetään 72 tunnin ajan" />
            <Legend status="delivered" legend="toimitettu vastaanottajalle" />
            <Legend status="open" legend="viesti avattu" />
            <Legend status="processed" legend="viestiä valmistellaan lähetykseen" />
          </>}
          {type !== 'email' && <>
            {/* sms statuses: https://gatewayapi.com/docs/rest.html#get-sms-and-sms-status */}
            <Legend status="delivered" legend="toimitettu vastaanottajalle" />
            <Legend status="enroute" legend="viesti mobiiliverkossa matkalla" />
            <Legend status="expired" legend="viesti ei saanut vahvistusta toimituksesta, toimitusta ei enää yritetä" />
            <Legend status="rejected" legend="mobiiliverkko hylkäsi viestin, tarkista numero" />
            <Legend status="undeliverable" legend="viestiä ei voida toimittaa, numero kannattaa tarkistaa" />
          </>}
        </DialogContent>

      </Dialog>
    </div>
  );
}
