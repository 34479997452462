import React, { useState, useEffect } from "react"
import { withLeaflet, Marker, Popup } from "react-leaflet"
import L from "leaflet"
import Control from "react-leaflet-control"
import { FaSearchLocation } from 'react-icons/fa'
import { greenIcon } from '../icons/icons'
import useScript from '../../../hooks/useScript'
import green from '@material-ui/core/colors/green';


function ClosestValveControl(props) {
  useScript('https://cdn.jsdelivr.net/npm/leaflet-knn@0.1.0/leaflet-knn.min.js')

  const { position, valves, locationStatus, locateValve, setLocateValve } = props

  const [closestValve, setClosestValve] = useState({})

  useEffect(() => {
    if (locateValve && locationStatus && locationStatus.latlng) {
      const gj = L.geoJson(valves);
      console.log('onValveLocationFound, valves, geoJson', valves, gj)

      const index = window.leafletKnn(gj)
      console.log('leafletKnn', index)
      const nearest = index.nearest(locationStatus.latlng, 1);
      console.log('leafletKnn.nearest', nearest)
      setClosestValve({ position: nearest[0] })
    }
  }, [setClosestValve, valves, locationStatus, locateValve])

  return (
    <>
      {valves && <Control position={position} className="leaflet-bar">
        <a href="#" role="button" className="leaflet-bar-part leaflet-bar-part-single" style={locateValve ? { color: green[600] } : {}} onClick={(e) => {
          e.preventDefault()
          setLocateValve(!locateValve);
        }}
          title={locateValve ? 'Lähimmän sulkuventtiilin etsintä on aktiivinen' : 'Etsi lähin sulkuventtiili'}>
          <FaSearchLocation />
        </a>

      </Control>}
      {locateValve && closestValve && closestValve.position && <Marker icon={greenIcon} position={closestValve.position} opacity={1}>
        <Popup autoClose={false}>
          <span>Lähin sulkuventtiili</span>
        </Popup>
      </Marker>}
    </>
  )
}

export default withLeaflet(ClosestValveControl)
