import React, { useState, useEffect } from "react"
import { withLeaflet } from "react-leaflet"
import "leaflet-lasso"
import L from "leaflet"
import Control from "react-leaflet-control"
import { GiLasso } from 'react-icons/gi'
import green from '@material-ui/core/colors/green';

// modelled from https://github.com/zakjan/leaflet-lasso

function LassoControl(props) {
  const { options, position, enabled, onLassoSelect } = props
  const { map } = props.leaflet

  const [lasso, setLasso] = useState({})
  const [lassoActive, setLassoActive] = useState(enabled)




  function handleCustomerSelection(customerIds) {
    console.log('in handleCustomerSelection with ', customerIds)
    onLassoSelect(customerIds);
  }

  // function resetSelectedState() {
  //   map.eachLayer(layer => {
  //     if (layer instanceof L.Marker) {
  //       layer.setIcon(new L.Icon.Default())
  //     } else if (layer instanceof L.Path) {
  //       // layer.setStyle({ color: '#3388ff' })
  //     }
  //   })
  // }

  function setSelectedLayers(layers) {
    console.log('lasso finished. Layers: ', layers)

    // resetSelectedState()

    const selectedCustomerIds = []

    layers.forEach(layer => {
      if (layer instanceof L.Marker) {
        // layer.setIcon(new L.Icon.Default({ className: 'selected ' }));
        console.log('got marker. Layer: ', layer)
        console.log('got marker: Position.Properties:', layer.options.position?.properties)
        if (layer.options?.position?.properties) {
          const customerId = layer.options.position.properties.id
          // console.log('got customer id: ', customerId)
          if (customerId) {
            selectedCustomerIds.push(customerId)
          }
        }
      }
      //  else if (layer instanceof L.Path) {
      //   // layer.setStyle({ color: '#ff4620' });
      //   layer.setStyle({ color: '#ffff00' })
      // }
    });
    const distinctSelected = [...new Set(selectedCustomerIds.filter(t => t))];
    console.log('selected customer ids in onLassoSelect in LassoControl', distinctSelected);
    if (distinctSelected && distinctSelected.length > 0) {
      handleCustomerSelection(distinctSelected)
    }
  }

  useEffect(() => {
    setLasso(L.lasso(map, options))

    // map.on('mousedown', () => {
    //   // resetSelectedState()
    // });
    map.on('lasso.finished', event => {
      setSelectedLayers(event.layers)
      setLassoActive(false)
    });
    map.on('lasso.enabled', () => {
      setLassoActive(true)
      // resetSelectedState()
    });
    map.on('lasso.disabled', () => {
      setLassoActive(false)
    })

    return function unMountLasso() {
      map.off('lasso.finished', event => {
        setSelectedLayers(event.layers)
        setLassoActive(false)
      });
      map.off('lasso.enabled', () => {
        setLassoActive(true)
        // resetSelectedState()
      });
      map.off('lasso.disabled', () => {
        setLassoActive(false)
      })
    }
  }, [])

  useEffect(() => {
    if (enabled) {
      lasso.enable()
    }
  }, [lasso, enabled])



  return (
    <Control position={position ?? 'topleft'} className="leaflet-bar">
      <a href="#" role="button" className="leaflet-bar-part leaflet-bar-part-single" style={lassoActive ? { color: green[600] } : {}} onClick={(e) => {
        e.preventDefault()
        // console.log('clicked on lasso control button in LassoControl component')
        // toggle lasso enabled state
        lasso.toggle()
      }}
        title="Valitse asikkaita lassotoiminnolla">
          {/* L */}
          <GiLasso />
        </a>
    </Control>
  );
}

export default withLeaflet(LassoControl);