import React from "react"
import { withLeaflet } from "react-leaflet"
import Control from "react-leaflet-control"
import '../css/mapbox.css'

function MapboxWordmark(props) {
  const { position } = props

  return (
    <Control position={position}>
      <a href="http://mapbox.com/about/maps" className="mapbox-wordmark" target="_blank" rel="noopener noreferrer">Mapbox</a>
    </Control>
  )
}

export default withLeaflet(MapboxWordmark)
