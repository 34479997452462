import React from "react";

export default function AppName(props) {
  const { provider } = props

  const appName = provider ? `Hero - ${provider}` : "Hero"

  return (
    <>{appName}</>
  );
}
