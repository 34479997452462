import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Grid } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import CustomToolbarSelect from './components/CustomToolbarSelect';
import { finnishTranslations } from '../../components/TableHelper/finnishTranslations'

import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import { boxMargin } from './components/defaults';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import FormLabel from '@material-ui/core/FormLabel';

// components
import PageTitle from '../../components/PageTitle/PageTitle';

import DetailsDialog from './components/DetailsDialog';

import { useUserState, acquireToken, useRedirectFlow } from "../../context/UserContext";
import { useConfigState } from "../../context/ConfigProvider";
import { useCustomerState } from "../../context/CustomerProvider";

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    padding: `${theme.spacing(2)}px`,
    margin: `${theme.spacing(0)}px`,
  },
  box: {
    // width: "80vw"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

export default function Messages(props) {
  // global
  const { providerId } = useUserState();
  const { customers } = useCustomerState();
  const { messagingApi } = useConfigState();
  const classes = useStyles();

  console.log('Messages with providerId', providerId)

  const [messages, setMessages] = useState([]) // messages from backend
  const [oneSelectedMessage, setOneSelectedMessage] = useState({}) // selected message to view details
  const [selectedMessages, setSelectedMessages] = useState([])
  const [messageDetails, setMessageDetails] = useState({})

  const [query, setQuery] = useState(props.query ?? {
    from: moment().startOf('month').format('YYYY-MM-DD'),
    to: moment().format('YYYY-MM-DD')
  }) // messages query (from, to)

  const [fromFieldValue, setFromFieldValue] = useState(query.from)
  const [toFieldValue, setToFieldValue] = useState(query.to)


  const [type, setType] = useState(props.type ?? 'email') // messages query type

  // dialogs
  const [detailsDialogOpen, setDetailsDialogOpen] = useState(false)

  // const messagesApiUri = {
  //   sms: `${process.env.REACT_APP_MESSAGING_API_URL}/sms/${providerId}`,
  //   email: `${process.env.REACT_APP_MESSAGING_API_URL}/email/${providerId}`
  // };

  // initial messages load
  useEffect(() => {
    // GET: get messages
    async function fetchMessages() {
      const url = type === 'sms' ? messagingApi.urls.sms : messagingApi.urls.email
      const tokenResponse = await acquireToken(messagingApi.tokenRequest, useRedirectFlow);
      axios
        .get(url, {
          params: query,
          headers: { 'Authorization': `Bearer ${tokenResponse.accessToken}` }
        })
        .then(response => {
          console.log('get messages promise ready');
          setMessages(response.data);
        });
    }
    fetchMessages();
  }, [query, messagingApi.tokenRequest, messagingApi.urls.sms, messagingApi.urls.email])

  useEffect(() => {
    // GET: get message details
    async function fetchMessageDetails() {
      const url = type === 'sms' ? messagingApi.urls.sms : messagingApi.urls.email
      const tokenResponse = await acquireToken(messagingApi.tokenRequest, useRedirectFlow);
      axios
        .get(`${url}/${oneSelectedMessage.id}`, {
          headers: { 'Authorization': `Bearer ${tokenResponse.accessToken}` }
        })
        .then(response => {
          console.log('get message details promise ready');
          setMessageDetails(response.data)
          setDetailsDialogOpen(true)
        });
    }

    if (oneSelectedMessage && oneSelectedMessage.id) {
      fetchMessageDetails()
    }
  }, [oneSelectedMessage, messagingApi.tokenRequest, messagingApi.urls.sms, messagingApi.urls.email])

  // handle open dialogs
  const openDetailsDialog = function (dataIndex) {
    console.log('details', dataIndex, messages[dataIndex]);
    setOneSelectedMessage(messages[dataIndex]);
  }

  const handleSearchClick = function () {
    setQuery(prevValue => {
      setQuery({ ...prevValue, from: fromFieldValue, to: toFieldValue })
    })
  }


  // tags table columns
  const columns = [
    {
      name: "id",
      label: "Id",
      options: {
        filter: false,
      }
    },
    {
      name: "status",
      label: "Tila",
      options: {
        filter: false,
      }
    },
    {
      name: "timestamp",
      label: "Aikaleima",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          value && new Date(value).toLocaleString()
        )

      }
    },
  ];


  return (
    <>
      <PageTitle title="Lähetetyt viestit" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Paper elevation={1} className={classes.paper}>
            <Box className={classes.box} margin={boxMargin}>
              <h4>Hae viestejä</h4>
            </Box>
            <Box className={classes.box} margin={boxMargin}>
              <FormLabel component="legend">Tyyppi</FormLabel>
              <RadioGroup aria-label="Viestin tyyppi" name="type" value={type} onChange={(e) => setType(e.target.value)}>
                <FormControlLabel value="email" control={<Radio />} label="Sähköposti" />
                <FormControlLabel value="sms" control={<Radio />} label="Tekstiviesti" />
              </RadioGroup>
            </Box>

            <Box className={classes.box} margin={boxMargin}>
              <TextField id="fromDate"
                label="alkaen"
                type="date"
                className={classes.textField}
                value={fromFieldValue}
                onChange={(e) => setFromFieldValue(e.target.value)}
              >
              </TextField>

              <TextField id="toDate"
                label="päättyen"
                type="date"
                className={classes.textField}
                value={toFieldValue}
                onChange={(e) => setToFieldValue(e.target.value)}
              >

              </TextField>
            </Box>
            <Box className={classes.box} margin={boxMargin}>
              <Button variant="contained"
                color="primary"
                onClick={handleSearchClick}>
                Hae viestejä <SearchIcon />
              </Button>
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <MUIDataTable
            data={messages}
            columns={columns}
            options={{
              textLabels: finnishTranslations,
              filter: false,
              filterType: 'checkbox',
              responsive: 'standard',
              selectableRows: 'single',
              selectableRowsHeader: false,
              fixedSelectColumn: false,
              selectableRowsOnClick: true,
              rowsSelected: selectedMessages,
              download: false,
              onRowSelectionChange: (currentRowSelected, allRowsSelected, rowsSelected) => {
                console.log('onRowsSelect', currentRowSelected, allRowsSelected, rowsSelected);
                if (allRowsSelected) {
                  setSelectedMessages(allRowsSelected.map(i => i.dataIndex))
                } else {
                  setSelectedMessages([]);
                }
              },
              customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
                <CustomToolbarSelect
                  selectedRows={selectedRows}
                  displayData={displayData}
                  setSelectedRows={setSelectedRows}
                  details={openDetailsDialog}
                />
              ),
            }}
          />
        </Grid>
      </Grid>

      <DetailsDialog
        selected={messageDetails}
        customers={customers}
        open={detailsDialogOpen}
        type={type}
        setOpen={setDetailsDialogOpen}
      />
    </>
  );
}
