import React, { useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Button, LinearProgress, FormControlLabel, Radio } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { Formik, Form, Field } from 'formik';
import { TextField, RadioGroup } from 'formik-material-ui';
import Paper from "@material-ui/core/Paper";
import * as Yup from "yup"
import Box from '@material-ui/core/Box';
import { boxMargin } from './defaults';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import MUIDataTable from 'mui-datatables';
import ConfirmMessageSendDialog from './ConfirmMessageSendDialog';

// use Formik for form state management, https://jaredpalmer.com/formik/
// use Yup for model validation, https://www.npmjs.com/package/yup


const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(5)}px ${theme.spacing(5)}px ${theme.spacing(5)}px`,
    margin: `${theme.spacing(5)}px ${theme.spacing(5)}px ${theme.spacing(5)}px`,
  },
  box: {
    // width: "80vw"
  },
  editForm: {
    width: "80vw"
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function MessageComposeDialog(props) {
  const classes = useStyles();
  const { open, setOpen, onSend, customers, selectedCustomers, methods } = props;

  const [confirmMessageDialogOpen, setConfirmMessageDialogOpen] = useState(false)
  const [message, setMessage] = useState({})

  // console.log('Message Compose dialog load with customers', selectedCustomers);

  const calculateSmsMessages = function (characters) {
    // console.log('calculating sms messages from', characters);
    let messageCount = 1;
    if (characters > 160) {
      messageCount = Math.ceil(characters / 153.0);
    }
    return messageCount;
  }


  const handleClose = () => {
    setOpen(false);
  };

  const handleSendConfirmation = () => {
    setOpen(false);
    onSend(selectedCustomers, message);
  }

  const validationSchema = Yup.object({
    emailReplyTo: Yup.string("Syötä vastausviestien sähköpostiosoite")
      .email("Sähköpostiosoitteen täytyy olla kelvollinen osoite")
  });

  // customers table columns
  const columns = [
    {
      name: "cid",
      label: "Asiakas id",
      options: {
        filter: false
      }
    },
    {
      name: "name",
      label: "Nimi",
      options: {
        filter: false,
      }
    },
    {
      name: "email",
      label: "Sähköposti",
      options: {
        filter: false
      }
    },
    {
      name: "mobile",
      label: "Puhelin",
      options: {
        filter: false
      }
    },
    {
      name: "info",
      label: "Tietoja",
      options: {
        filter: false,
        display: 'false',
      }
    }
  ];

  const customerIdColumnIndex = columns.map(c => c.name).indexOf('cid');
  const methodLabels = {
    'email': 'Sähköposti',
    'sms': 'Tekstiviesti'
  }


  return (
    <div>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} title="Sulje lähettämättä" aria-label="Sulje lähettämättä">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              <span>Viestin lähetys {selectedCustomers.length} vastaanottajalle</span>
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
              Sulje lähettämättä
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <DialogContentText>
            Tällä työkalulla voit lähettää viestin valituille asiakkaille.

          </DialogContentText>
          <Paper elevation={1} className={classes.paper}>
            <Formik
              initialValues={{ method: methods[0], emailReplyTo: '', emailSubject: '', emailMessage: '', smsSender: '', smsMessage: '' }}
              validationSchema={validationSchema}
              onSubmit={(values, { isSubmitting, setSubmitting }) => {
                if (isSubmitting) {
                  return;
                }
                console.log('message compose dialog, onSubmit @', new Date());
                setSubmitting(false);

                setMessage(values);
                setConfirmMessageDialogOpen(true);
              }}
            >
              {({ submitForm, isSubmitting, touched, errors, values }) => (
                <Form className={classes.editForm}>
                  <Box className={classes.box} margin={boxMargin}>
                    <Field component={RadioGroup} name="method">
                      {methods.map(m =>                       
                        <FormControlLabel
                          key={m}
                          value={m}
                          control={<Radio disabled={isSubmitting} />}
                          label={methodLabels[m] ?? m}
                          disabled={isSubmitting}
                        />)}
                      {/* <FormControlLabel
                        value="email"
                        control={<Radio disabled={isSubmitting} />}
                        label="Sähköposti"
                        disabled={isSubmitting}
                      />
                      <FormControlLabel
                        value="sms"
                        control={<Radio disabled={isSubmitting} />}
                        label="Tekstiviesti"
                        disabled={isSubmitting}
                      /> */}
                    </Field>
                  </Box>
                  {values.method === 'email' &&
                    <Box className={classes.box} margin={boxMargin}>
                      <Box className={classes.box} margin={boxMargin}>
                        <Field type="text" name="emailReplyTo" label="Vastaukset osoitteeseen" component={TextField} fullWidth />
                        <small className="form-text text-muted">Kirjoita sähköpostiosoite, johon vastaukset halutaan. Jätä
                    tyhjäksi, jos vastauksia ei haluta.</small>
                      </Box>
                      <Box className={classes.box} margin={boxMargin}>
                        <Field type="text" name="emailSubject" label="Viestin aihe" component={TextField} fullWidth />
                        <small className="form-text text-muted">Sähköpostin otsikko (subject).</small>
                      </Box>
                      <Box className={classes.box} margin={boxMargin}>
                        <Field type="text" name="emailMessage" label="Sähköpostiviesti" component={TextField} fullWidth multiline />
                      </Box>
                    </Box>
                  }
                  {values.method === 'sms' &&
                    <Box className={classes.box} margin={boxMargin}>
                      <Field type="text" name="smsMessage" label="Tekstiviesti" component={TextField} fullWidth multiline />
                      <span className="text-muted form-text">Merkkien määrä: {values.smsMessage.length}, viestejä per
                    vastaanottaja: {calculateSmsMessages(values.smsMessage.length)}</span>
                    </Box>
                  }
                  {isSubmitting && <LinearProgress />}

                  <Box className={classes.box} margin={boxMargin}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth
                      disabled={isSubmitting}
                    >
                      Lähetä
                  </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          </Paper>

            <MUIDataTable
              title="Viestin vastaanottajat"
              data={customers ? customers.filter(c => selectedCustomers.some(s => s.id === c.id)) : []}
              columns={columns}
              options={{
                filter: false,
                download: false,
                print: false,
                responsive: 'standard',
                selectableRows: 'none',
                customSort: (data, colIndex, order) => {
                  return data.sort((a, b) => {
                    if (colIndex === customerIdColumnIndex) {
                      return (parseInt(a.data[colIndex]) < parseInt(b.data[colIndex]) ? -1 : 1) * (order === 'desc' ? 1 : -1);
                    } else {
                      return (a.data[colIndex] < b.data[colIndex] ? -1 : 1) * (order === 'desc' ? 1 : -1);
                    }
                  });
                },
              }}
            />
        </DialogContent>

      </Dialog>

      <ConfirmMessageSendDialog open={confirmMessageDialogOpen} setOpen={setConfirmMessageDialogOpen} onConfirm={handleSendConfirmation} message={message} recipients={selectedCustomers} />
    </div>
  );
}
