import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Button, LinearProgress, Divider, FormLabel } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { Formik, Form, Field, FieldArray } from 'formik';
import { TextField } from 'formik-material-ui';
import Paper from "@material-ui/core/Paper";
import * as Yup from "yup"
import Box from '@material-ui/core/Box';
import { boxMargin } from './defaults';
import DeleteIcon from "@material-ui/icons/Delete";
import InputLabel from '@material-ui/core/InputLabel';
import NativeSelect from '@material-ui/core/NativeSelect';
import { Autocomplete } from 'formik-material-ui-lab';
import MuiTextField from '@material-ui/core/TextField'

// use Formik for form state management, https://jaredpalmer.com/formik/
// use Yup for model validation, https://www.npmjs.com/package/yup


const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(5)}px ${theme.spacing(5)}px ${theme.spacing(5)}px`,
    margin: `${theme.spacing(5)}px ${theme.spacing(5)}px ${theme.spacing(5)}px`,
  },
  box: {
    // width: "80vw"
  },
  editForm: {
    width: "80vw"
  },
  adjacentField: {
    marginRight: "5px"
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function EditDialog(props) {
  const classes = useStyles();
  const { open, setOpen, onSave, selected, roles } = props;

  console.log('EditDialog load');
  console.log('selected data: ', selected);
  console.log('usage roles: ', roles);

  const handleClose = () => {
    setOpen(false);
  };

  const validationSchema = Yup.object({
    dn: Yup.string("Tunnisteen nimi")
      .required("Tunnisteen nimi on pakollinen tieto"),
  });

  return (
    <div>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              <span>{selected.dn}</span>
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
              Sulje tallentamatta
            </Button>
          </Toolbar>
        </AppBar>

        <Paper elevation={1} className={classes.paper}>
          <Formik
            initialValues={(Array.isArray(selected.props)) ? selected : { ...selected, props: [] }}
            validationSchema={validationSchema}
            onSubmit={(values, { isSubmitting, setSubmitting }) => {
              if (isSubmitting) {
                return;
              }
              setSubmitting(false);
              setOpen(false);
              console.log('saving tag edit', values)
              onSave(selected.id, selected.tid, values);
            }}
          >
            {({ submitForm, isSubmitting, touched, errors, values, setFieldValue }) => (
              <Form className={classes.editForm}>

                <Box className={classes.box} margin={boxMargin}>
                  <Field type="text" name="tid" label="Id" component={TextField} fullWidth disabled />
                </Box>
                <Box className={classes.box} margin={boxMargin}>
                  <Field type="text" name="dn" label="Tunnisteen nimi" component={TextField} fullWidth />
                </Box>
                <Box className={classes.box} margin={boxMargin}>
                  <Field
                    name="role"
                    fullWidth
                    freeSolo={true}
                    component={Autocomplete}
                    options={roles}
                    getOptionLabel={(option) => option}
                    onInputChange={(e, v, r) => {
                      // see details: https://material-ui.com/api/autocomplete/
                      // handle input change so the user does not need to press enter to accept free solo value
                      // pressing enter on single line input submits the form...
                      if (r==='input') {
                        setFieldValue("role", v)
                      }
                    }}
                    renderInput={(params) => (
                      <MuiTextField
                        {...params}
                        error={touched['role'] && !!errors['role']}
                        helperText={touched['role'] && errors['role']}
                        label="Käyttötarkoitus"
                        placeholder="käyttötarkoitus"
                      />
                    )}
                  />


                </Box>
                <Box className={classes.box} margin={boxMargin}>
                  <Field type="text" name="info" label="Tietoja" component={TextField} fullWidth multiline />
                </Box>

                <Divider />

                <Box className={classes.box} margin={boxMargin}>
                  <FormLabel>Ominaisuudet</FormLabel>
                </Box>
                <Box className={classes.box} margin={boxMargin + 2}>
                  <FieldArray
                    name="props"
                    render={arrayHelpers => (
                      <div>
                        {values.props.map((prop, index) => (
                            <div key={index}>
                              <Box className={classes.box} margin={boxMargin}>
                                <Field className={classes.adjacentField} type="text" name={`props[${index}].name`} label="Nimi" component={TextField} />
                                <Field className={classes.adjacentField} type={prop.type ?? "text"} name={`props[${index}].value`} label="Arvo" component={TextField} />

                                <Button type="button" variant="text" color="secondary" title="Poista ominaisuus" size="small"
                                  onClick={() => arrayHelpers.remove(index)}
                                >
                                  <DeleteIcon />
                                </Button>
                              </Box>
                              <Divider />
                            </div>
                          )
                        )}

                        <Box className={classes.box} margin={boxMargin}>
                          <InputLabel htmlFor="add-new-prop-with-type-select">Lisää uusi ominaisuus</InputLabel>
                          <NativeSelect
                            onChange={(e) => {
                              const { value } = e.target;
                              if (value) {
                                arrayHelpers.push({
                                  name: '',
                                  value: '',
                                  type: value
                                })
                                e.target.value = ''
                              }
                            }}
                            label="Ominaisuuden tyyppi"
                            inputProps={{
                              name: 'new-prop-type-select',
                              id: 'add-new-prop-with-type-select',
                            }}
                          >
                            <option aria-label="None" value="" />
                            <option value={'text'}>Teksti</option>
                            <option value={'number'}>Numero</option>
                            <option value={'email'}>Sähköpostiosoite</option>
                            <option value={'tel'}>Puhelinnumero</option>
                            <option value={'date'}>Päivämäärä</option>
                            <option value={'time'}>Kellonaika</option>
                          </NativeSelect>
                        </Box>
                      </div>
                    )}
                  />
                </Box>

                <Divider />



                {isSubmitting && <LinearProgress />}

                <Box className={classes.box} margin={boxMargin}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={isSubmitting}
                  >
                    Tallenna
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Paper>
      </Dialog>
    </div>
  );
}
