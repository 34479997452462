import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import IndeterminateCheckBoxIcon from "@material-ui/icons/IndeterminateCheckBox";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { withStyles } from "@material-ui/core/styles";

const defaultToolbarSelectStyles = {
  iconButton: {
  },
  iconContainer: {
    marginRight: "24px",
  },
  inverseIcon: {
    transform: "rotate(90deg)",
  },
};

class CustomToolbarSelect extends React.Component {
  handleClickInverseSelection = () => {
    const nextSelectedRows = this.props.displayData.reduce((nextSelectedRows, _, index) => {
      if (!this.props.selectedRows.data.find(selectedRow => selectedRow.index === index)) {
        nextSelectedRows.push(index);
      }

      return nextSelectedRows;
    }, []);

    this.props.setSelectedRows(nextSelectedRows);
  };

  handleClickDeselectAll = () => {
    this.props.setSelectedRows([]);
  };

  // handleClickBlockSelected = () => {
  //   console.log(`block users with dataIndexes: ${this.props.selectedRows.data.map(row => row.dataIndex)}`);
  // };

  handleClickEditSelected = () => {
    console.log(`Edit with dataIndexes: ${this.props.selectedRows.data.map(row => row.dataIndex)}`);
    this.props.edit(this.props.selectedRows.data[0].dataIndex);
  };

  handleClickDeleteSelected = () => {
    console.log(`Delete with dataIndexes: ${this.props.selectedRows.data.map(row => row.dataIndex)}`);
    this.props.delete(this.props.selectedRows.data[0].dataIndex);
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.iconContainer}>
        {this.props.selectedRows.data.length === 1 && <Tooltip title={"Muokkaa tunnistetta"}>
          <IconButton className={classes.iconButton} onClick={this.handleClickEditSelected}>
            <EditIcon className={classes.icon} />
          </IconButton>
        </Tooltip>}
        
        {this.props.selectedRows.data.length === 1 && <Tooltip title={"Poista valittu tunniste"}>
          <IconButton className={classes.iconButton} onClick={this.handleClickDeleteSelected}>
            <DeleteIcon className={classes.icon} />
          </IconButton>
        </Tooltip>}

        <Tooltip title={"Poista valinnat"}>
          <IconButton className={classes.iconButton} onClick={this.handleClickDeselectAll}>
            <IndeterminateCheckBoxIcon className={classes.icon} />
          </IconButton>
        </Tooltip>
      </div>
    );
  }
}

export default withStyles(defaultToolbarSelectStyles, { name: "CustomToolbarSelect" })(CustomToolbarSelect);