import React, { useState, useEffect, useRef } from 'react';
import { Map, Marker, Popup, GeoJSON, FeatureGroup, LayersControl, ScaleControl } from 'react-leaflet'
import { latLngBounds } from 'leaflet'

import 'react-leaflet-fullscreen/dist/styles.css'
import FullscreenControl from 'react-leaflet-fullscreen';

import L from 'leaflet'

import LocateControl from './components/LocateControl';
import LassoControl from './components/LassoControl';
import LocationInfo from './components/LocationInfo';
import DefaultLayers from './components/DefaultLayers';
import OpenMarker from './components/OpenMarker';
import MapboxWordmark from './components/MapboxWordmark';
import WeatherControl from './components/weatherControl';
import ClosestValveControl from './components/ClosestValveControl';

import { orangeIcon } from './icons/icons'

import { useConfigState } from "../../context/ConfigProvider";


import './css/chamberMarker.css'

const { Overlay } = LayersControl

const GeoJsonLayerStyle = {
  "valves": {
    "color": '#ff0000',
    "fillColor": '#ff0000'
  },
  "chambers": {
    "color": '#00ffff'
  },
  "network": {
    "color": '#00ff00'
  },
  "valves.json": {
    "color": '#ff0000',
    "fillColor": '#ff0000'
  },
  "chambers.json": {
    "color": '#00ffff'
  },
  "network.json": {
    "color": '#00ff00'
  }
};

const GeoJsonLayerName = {
  "valves": "Sulkuventtiilit",
  "network": "Verkosto",
  "chambers": "Kaivot",
  "valves.json": "Sulkuventtiilit",
  "network.json": "Verkosto",
  "chambers.json": "Kaivot"
}


const locateOptions = {
  position: 'topleft',
  strings: {
    title: 'Paikanna minut',
    popup: 'Sijaintitiedon tarkkuus: {distance} {unit}',
    metersUnit: 'm'
  },
  keepCurrentZoomLevel: true,
  enableHighAccuracy: true, // TODO: test this. Might do nothing currently. Should propably implement https://github.com/M165437/Leaflet.AccuratePosition as react component
  watch: true, // TODO: test if this has any effect. The goal is to allow user location to be constantly updated.
  onActivate: () => { }, // callback before engine starts retrieving locations
};

export default function MapBase(props) {
  const { customers, selectedCustomers, geoJsonData, onCustomerSelect } = props;

  const { config } = useConfigState();

  const [markers, setMarkers] = useState([]);
  const [selectedMarkers, setSelectedMarkers] = useState([]);
  const [locationStatus, setLocationStatus] = useState({});
  const [locateValve, setLocateValve] = useState(false);
  const [geoJsonItems, setGeoJsonItems] = useState([]);

  const mapZoomAtStart = 13;
  const mapZoomWhenSelectingCustomer = 15;

  // Center map to Kuopio
  const startPosition = config?.map?.startPosition ?? {
    lat: 62.89238,
    lng: 27.67703,
    zoom: mapZoomAtStart
  };

  // console.log('customers on map', customers);

  // leaflet map instance.
  const map = useRef(null);


  // useEffect(() => {
  //   console.log('MapBase, initial effect. Map=', map);
  // }, [])

  // create faded markers for all customers
  useEffect(() => {
    // handle single customer select
    const handleMarkerClick = (customerId) => {
      onCustomerSelect([customerId]); // sets state on parent. Param is expected to be an array
    }

    if (customers && customers.length > 0) {
      // console.log('customers on map, useEffect', customers);
      // console.log('selected customers on map, useEffect', selectedCustomers);

      // get customers with location info
      const customersWithLocation = filterCustomersWithLocation(customers);

      // set customers ids for selected customers who has location
      const selectedMarkersForCustomerIds = selectedCustomers && selectedCustomers.length > 0 ? customersWithLocation.filter(c => selectedCustomers.some(s => s.id === c.id)).map(c => c.id) : [];
      // set customer ids for non-selected customers who has location (i.e. all other customers with location)
      const defaultMarkersForCustomerIds = customersWithLocation.filter(c => !selectedMarkersForCustomerIds.includes(c.id)).map(c => c.id);

      // create faded (opacity=0.5) markers for non-selected customers
      // TODO: fix popup content!
      setMarkers(customersWithLocation.filter(c => defaultMarkersForCustomerIds.includes(c.id)).map(c =>
        <Marker key={`cm-${c.id}`} position={Object.assign({}, c.location[0], { properties: { ...c.location[0].properties, id: c.id } })} opacity={0.5}>
          <Popup>
            <div>
              <p>
                <strong>Asiakas</strong>: {c.cid}<br />
                Nimi:{c.name}<br />
                Puhelin:{c.mobile}
              </p>
              <button type="button" onClick={() => handleMarkerClick(c.id)}>Valitse</button>
            </div>
          </Popup>
        </Marker>
      ));

      // make markers for selected customers
      if (selectedMarkersForCustomerIds.length > 20) {
        setSelectedMarkers(customersWithLocation.filter(c => selectedMarkersForCustomerIds.includes(c.id)).map(c =>
          <Marker icon={orangeIcon} key={`sm-${c.id}`} position={c.location[0]} opacity={1}>
            <Popup autoClose={false}>
              <p>
                <strong>Asiakas</strong>: {c.cid}<br />
                Nimi:{c.name}<br />
                Puhelin:{c.mobile}
              </p>
            </Popup>
          </Marker>
        ));
      }
      else {
        setSelectedMarkers(customersWithLocation.filter(c => selectedMarkersForCustomerIds.includes(c.id)).map(c =>
          <OpenMarker icon={orangeIcon} key={`sm-${c.id}`} position={c.location[0]} opacity={1}>
            <Popup autoClose={false}>
              <p>
                <strong>Asiakas</strong>: {c.cid}<br />
                Nimi:{c.name}<br />
                Puhelin:{c.mobile}
              </p>
            </Popup>
          </OpenMarker>
        ));
      }

      // create map bouds for customers
      if (selectedCustomers && selectedCustomers.length > 0) {
        if (selectedCustomers.length === 1) {
          // only one customer selected -> zoom to that marker
          const selected = customersWithLocation.find(c => c.id === selectedCustomers[0].id);
          if (selected && selected.location && selected.location.length > 0) {
            if (map.current?.leafletElement) {
              map.current.leafletElement.setView(selected.location[0], mapZoomWhenSelectingCustomer);
            }
          }
        } else {
          // create locations to set map view to show all selected customers
          const bounds = createMapBounds(customersWithLocation.filter(c => selectedMarkersForCustomerIds.includes(c.id)));
          if (bounds) {
            if (map.current?.leafletElement) {
              map.current.leafletElement.fitBounds(bounds);
            }
          }
        }
      }
      else {
        // create locations to set map view to show all customers
        const bounds = createMapBounds(customersWithLocation);
        if (bounds) {
          if (map.current?.leafletElement) {
            map.current.leafletElement.fitBounds(bounds);
          }
        }
      }
    }
  }, [customers, selectedCustomers, onCustomerSelect]);

  useEffect(() => {
    function getOnGeoJsonClick(key) {
      switch (key) {
        case 'network':
        case 'network.json':
          // TODO: make network select/click to work properly
          return (e) => {
            console.log('clicked geo json', key);
            // Assign new selected
            const selected = e.layer;
            // Bring selected to front
            selected.bringToFront();
            // Style selected
            selected.setStyle({
              color: "red"
            });
          }
        case 'valves':
        case 'valves.json':
          return (e) => {
            console.log('clicked geo json', key);
            //activatePipes(e); // currently does not work 
          }
        default:
        // do nothing
      }
    }
    function getOnEachGeoJsonFeature(key) {
      switch (key) {
        case 'valves':
        case 'valves.json':
          return (feature, layer) => {
            // console.log('geojson features:', feature, layer);
            const content = `<b>Sulkuventtiili</b><br />Tunnus: ${feature.properties?.valve_ID ?? ''}<br />Tyyppi: ${feature.properties?.valve_type ?? ''}`;
            layer.bindPopup(content);
          }
        case 'chambers':
        case 'chambers.json':
          return (feature, layer) => {
            // TODO: make chamber properties better and show measurements from chamber...

            // console.log('geojson features:', feature, layer);
            const content = `<b>Mittakaivo</b><br />${feature.properties?.tagName ?? ''}`; // 'Chamber<br/>' + JSON.stringify(feature.properties, null, 2);
            layer.bindPopup(content);
          }
        case 'network':
        case 'network.json':
          return (feature, layer) => {
            // console.log('geojson features:', feature, layer);
            const content = `<b>Putki</b><br/>Materiaali: ${feature.properties?.pipe_material ?? 'N/A'}<br />Pituus: ${feature.properties?.pipe_length ?? 'N/A'} m`
            layer.bindPopup(content);
          }
        default:
        // do nothing
      }
    }
    function createGeoJsonData(key, data) {
      if (data) {
        console.log('creating GeoJson from data', key, data);

        switch (key) {
          case 'chambers':
          case 'chambers.json':
            return (<GeoJSON data={data}
              key={key}
              style={GeoJsonLayerStyle[key]}
              onClick={getOnGeoJsonClick(key)}
              onEachFeature={getOnEachGeoJsonFeature(key)}
              pointToLayer={function (f, l) {
                // return L.marker(l, {icon: orangeIcon})
                const marker = L.divIcon({ className: "chamberIcon", html: "" })
                return new L.marker(l, { icon: marker, riseOnHover: true })
              }} />)

          default:
            return (<GeoJSON data={data}
              key={key}
              style={GeoJsonLayerStyle[key]}
              onClick={getOnGeoJsonClick(key)}
              onEachFeature={getOnEachGeoJsonFeature(key)}
            />)
        }
      }
      return null;
    }
    // console.log('creating geo json items in effect from', geoJsonData)
    if (geoJsonData) {
      const gjItems = Object.keys(geoJsonData).map((key) => {
        return { key: key, data: createGeoJsonData(key, geoJsonData[key])}
      });
      // console.log('created geo json items', gjItems)
      setGeoJsonItems(gjItems);
    }
  }, [geoJsonData])

  /* helper functions */
  // function activatePipes(valve) {
  //   //TODO: this is a copy from old code. Have no idea what this should do... Apparently this has never worked...
  //   // probably when user clicks on a valve then connected pipes (e.g. network) should light up...

  //   var pipe;
  //   // // Style selected
  //   // pipe.setStyle({
  //   //   color: "green"
  //   // });

  //   var markers = map.current._layers; // --> markers is undefined --> fix finding layers
  //   console.log('activatePipes, markers', markers);
  //   var pipes = [];
  //   for (var i in markers) {
  //     //iterate through all pipes
  //     if (markers[i].type == "Verkosto") { // this if clause does not match to current data. Could use key === 'network' ?
  //       pipes.push(markers[i])
  //     }
  //   }
  //   console.log(pipes)
  //   var valvelat = valve.latlng.lat;
  //   var valvelng = valve.latlng.lng;
  //   for (const x in pipes) {
  //     //go through all pipes and check if the pipe is below this valve
  //     var lat1 = pipes[x]._latlngs[0].lat;
  //     var lat2 = pipes[x]._latlngs[0].lat;
  //     var lng1 = pipes[x]._latlngs[1].lng;
  //     var lng2 = pipes[x]._latlngs[1].lng;
  //     if (lat1 <= valvelat && valvelat <= lat2 && lng1 <= valvelng && valvelng <= lng2) {
  //       console.log("Found the pipe")
  //     }
  //   }
  // }

  // filter customers list to contain only those who has location info
  function filterCustomersWithLocation(customersList) {
    if (customersList && customersList.length > 0) {
      return customersList.filter(c => (c.location && c.location.length > 0));
    }
    return [];
  }

  // create leaflet latLngBounds to show all items in map view
  function createMapBounds(customersWithLocationList) {
    if (customersWithLocationList && customersWithLocationList.length > 0) {
      const customerLocations = customersWithLocationList.map(c => [c.location[0].lat, c.location[0].lng]);
      // console.log('cust loc', customerLocations);
      const bounds = new latLngBounds(customerLocations);
      return bounds;
    }
    return null;
  }



  // handle customer selection from lasso operation
  function handleLassoSelectedCustomers(selectedCustomerIds) {
    console.log('handle lasso selected customer in MapBase', selectedCustomerIds);
    onCustomerSelect(selectedCustomerIds);
  }

  function handleLocateDeactivate() {
    console.log('locate deactivated');
    setLocationStatus({});
  }

  function onLocationFound(e) {
    console.log('paikannettu, tarkkuus', e.accuracy);

    setLocationStatus({ accuracy: e.accuracy, latlng: e.latlng });
  }

  function onLocationError(e) {
    //if there is an error in user locating
    setLocationStatus({});
    // console.log(e);
  }

  function onMapClick(e) {
    console.log('map clicked at ', e)
  }


  return (
    <>
      <Map ref={map}
        center={[startPosition.lat, startPosition.lng]}
        zoom={startPosition.zoom}
        onLocationFound={(e) => onLocationFound(e)}
        onLocationError={(e) => onLocationError(e)}
        onClick={(e) => onMapClick(e)}
      >
        <DefaultLayers position='topright'>
          <Overlay checked name='Asiakkaat'>
            <FeatureGroup>
              {markers}
            </FeatureGroup>
          </Overlay>

          {geoJsonItems && geoJsonItems.map(item =>
            <Overlay checked name={GeoJsonLayerName[item.key]} key={item.key}>
              <FeatureGroup>
                {item.data}
              </FeatureGroup>
            </Overlay>)}
          {/* {geoJsonData && Object.keys(geoJsonData).map((key) =>
            <Overlay checked name={GeoJsonLayerName[key]} key={key}>
              <FeatureGroup>
                {createGeoJsonData(key, geoJsonData[key])}
              </FeatureGroup>
            </Overlay>)} */}

        </DefaultLayers>

        <FeatureGroup>
          {selectedMarkers}
        </FeatureGroup>

        <FullscreenControl position="topleft" />

        <LocateControl options={locateOptions} onDeactivate={handleLocateDeactivate} startDirectly={locateValve} />
        <LassoControl {...props} onLassoSelect={handleLassoSelectedCustomers} />
        <ClosestValveControl position="topleft" valves={geoJsonData['valves'] ?? geoJsonData['valves.json']} locationStatus={locationStatus} locateValve={locateValve} setLocateValve={setLocateValve} />

        <MapboxWordmark position="bottomleft" />
        <ScaleControl position="bottomleft" imperial={false} />
        <LocationInfo position="bottomleft" locationStatus={locationStatus} />

        <WeatherControl position="bottomright" coolOff={10000} />
      </Map>
    </>
  );
}