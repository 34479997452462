import React from "react";

import {
  msalApp,
  requiresInteraction,
  isIE,
  GRAPH_REQUESTS
} from "./auth-utils";

// implementing msal auth. Inspiration from https://github.com/AzureAD/microsoft-authentication-library-for-js/tree/f05dfaae8c9cea9d0e8f93be4ff14779014ad42d/samples/react-sample-app

var UserStateContext = React.createContext();
var UserDispatchContext = React.createContext();

const useRedirectFlow = isIE();

function userReducer(state, action) {
  console.log('userReducer, action.type', action.type);
  switch (action.type) {
    case "LOGIN_SUCCESS":
      console.log('userReducer, account', action.account);
      return {
        ...state,
        isAuthenticated: true,
        account: action.account
      };
    case "SIGN_OUT_SUCCESS":
    case "SSO_LOGOUT_SUCCESS":
    case "USER_CANCELLED_TOKEN_REQUEST":
    case "LOGIN_FAILURE":
    case "NO_ACCOUNT":
    case "IDTOKEN_NOT_VALID":
      return { ...state, isAuthenticated: false, account: null, accessToken: null };
    // case "SET_ACCESS_TOKEN":
    //   return { ...state, accessToken: action.token };
    case "SET_PROVIDER_ID":
      if (action.providerId) {
        localStorage.setItem('providerId', action.providerId);
        return { ...state, providerId: action.providerId };
      }
      return { ...state };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider({ children }) {
  // const [account, setAccount] = React.useState(msalApp.getAccount());
  const [error, setError] = React.useState('');

  var [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated: !!localStorage.getItem("msal.idtoken"),
    account: msalApp.getAccount()
  });

  React.useEffect(() => {
    console.log('UserProvider, initial useEffect');

    async function doAuth() {
      msalApp.handleRedirectCallback(error => {
        if (error) {
          const errorMessage = error.errorMessage
            ? error.errorMessage
            : "Unable to acquire access token.";
          // setState works as long as navigateToLoginRequestUrl: false
          setError(errorMessage);
        }
      });

      const account = msalApp.getAccount();
      console.log('UserProvider, initial useEffect, account', account);

      if (account) {
        const now = Math.floor(Date.now() / 1000)
        console.log('comparing id token validity to now', now)
        if (account.idToken.nbf > now || now > account.idToken.exp) {
          console.log('account id token is not valid', account.idToken);
          dispatch({ type: "IDTOKEN_NOT_VALID" })
        }
      } else {
        console.log('account is null / undefined');
        dispatch({ type: "NO_ACCOUNT" })
      }
    }

    doAuth();

    // set latest used providerId from session
    const providerId = localStorage.getItem("providerId")
    if (providerId) {
      dispatch({ type: 'SET_PROVIDER_ID', providerId })
    }
  }, [dispatch]);

  React.useEffect(() => {
    if (error) {
      console.error(error)
    }
  }, [error])

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  var context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

function useUserDispatch() {
  var context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
}

export { UserProvider, useUserState, useUserDispatch, loginUser, signOut, ssoLogout, acquireToken, useRedirectFlow };

// ###########################################################

async function acquireToken(request, redirect) {
  console.log('acquiring token', request, redirect)
  return msalApp.acquireTokenSilent(request).catch(error => {
    console.log('acquireTokenSilent error', error)
    // Call acquireTokenPopup (popup window) in case of acquireTokenSilent failure
    // due to consent or interaction required ONLY
    if (requiresInteraction(error.errorCode)) {
      return redirect
        ? msalApp.acquireTokenRedirect(request)
        : msalApp.acquireTokenPopup(request);
    } else {
      console.error("Non-interactive error:", error.errorCode);
    }
  });
}

function loginUser(dispatch, history, setIsLoading, setError) {
  setError(false);
  setIsLoading(true);

  console.log('on loginUser')
  // onSignIn();
  // const loginResponse = msalApp
  msalApp
    .loginPopup(GRAPH_REQUESTS.LOGIN)
    .then(loginResponse => {
      console.log('msal login promise ready', loginResponse)
      if (loginResponse) {
        // localStorage.setItem('id_token', loginResponse.idToken)
        setError(null)
        setIsLoading(false)
        dispatch({ type: 'LOGIN_SUCCESS', account: loginResponse.account, useRedirectFlow: useRedirectFlow })

        history.push('/app/dashboard')
      } else {
        dispatch({ type: "LOGIN_FAILURE" });
        setError(true);
        setIsLoading(false);
      }
    })
    .catch(error => {
      console.log('msal login error:', error)
      setError(error.message);
      setIsLoading(false);
    });
}

function signOut(dispatch, history) {
  msalApp.logout();
  dispatch({ type: "SIGN_OUT_SUCCESS" });
  history.push("/login");
}

function ssoLogout(dispatch, history) {
  msalApp.logout();
  dispatch({ type: "SSO_LOGOUT_SUCCESS" });
  history.push("/login");
}