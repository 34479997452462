import React, { Component } from "react";
import { withLeaflet } from "react-leaflet";
import Locate from "leaflet.locatecontrol";

import Toast from "../../Toast";

// modelled after https://stackoverflow.com/a/54119973

class LocateControl extends Component {
  constructor(props) {
    super(props);
    this.state = { alertOpen: false };
  }

  componentDidMount() {
    const { options, startDirectly, onDeactivate } = this.props;
    const { map } = this.props.leaflet;

    options.onLocationError = () => { 
      this.setState({ alertOpen: true });
    }

    // const lc = new Locate(options);
    this.lc = new Locate(options);

    if (onDeactivate) {
      // override Locate _deactivate to send deactivation event to handler
      // then execute the original _deactivate function
      this.lc._deactivate = (function (_super) {
        return function () {
          onDeactivate();
          return _super.apply(this, arguments);
        };
      })(this.lc._deactivate);
    }
    this.lc.addTo(map);

    console.log('LocateControl startDirectly', startDirectly)

    if (startDirectly) {
      // request location update and set location
      this.lc.start();
    }
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.startDirectly !== prevProps.startDirectly) {
      if (this.props.startDirectly) {
        this.lc.start();
      }
    }
  }

  handleAlertClose = (event, reason) => {
    this.setState({ alertOpen: false });
  };

  render() {
    return (
      <Toast severity="error" 
        onClose={this.handleAlertClose} 
        open={this.state.alertOpen} 
        autoHideDuration={8000}
      >
        Paikannus ei onnistunut. Tarkista, että olet sallinut paikannuksen käyttämisen.
      </Toast>
    )
  }
}

export default withLeaflet(LocateControl);