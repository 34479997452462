import React from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import Typography from '@material-ui/core/Typography';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

export default function Toast(props) {
  // console.log('toast props', props)

  const { children, open, autoHideDuration, severity, onClose, title, message } = props

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    onClose()
  }

  return (
    <Snackbar open={open} autoHideDuration={autoHideDuration} onClose={handleClose}>
      <Alert onClose={handleClose} severity={severity}>
        {title && <Typography gutterBottom variant="h6" component="h2">
          {title}
        </Typography>}
        {message && <Typography variant="body2" color="textSecondary" component="div">
          {message}
        </Typography>}
        {children}
      </Alert>
    </Snackbar>
  );
}
