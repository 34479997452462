import React, { useState, useEffect } from "react"
import { useUserState, acquireToken, useRedirectFlow } from "./UserContext"
import axios from 'axios';

const ConfigContext = React.createContext()

const crmTokenRequest = {
  scopes: [
    process.env.REACT_APP_MSAL_SCOPE_CRM_READ,
    process.env.REACT_APP_MSAL_SCOPE_CRM_WRITE
  ]
}
const messagingTokenRequest = {
  scopes: [
    process.env.REACT_APP_MSAL_SCOPE_MESSAGING_READ,
    process.env.REACT_APP_MSAL_SCOPE_MESSAGING_WRITE
  ]
}
const mapTokenRequest = {
  scopes: [
    process.env.REACT_APP_MSAL_SCOPE_MAP_READ
  ]
}


function ConfigProvider(props) {
  // global
  const { providerId, isAuthenticated } = useUserState()

  const configsApi = {
    url: `${process.env.REACT_APP_CONFIGS_API_URL}/hero`,
    tokenRequest: crmTokenRequest
  }
  const customersApi = {
    url: `${process.env.REACT_APP_CUSTOMERS_API_URL}/${providerId}`,
    tokenRequest: crmTokenRequest
  }
  const tagsApi = {
    url: `${process.env.REACT_APP_TAGS_API_URL}/${providerId}`,
    tokenRequest: crmTokenRequest
  }
  const readingsApi = {
    url: `${process.env.REACT_APP_READINGS_API_URL}/${providerId}`,
    tokenRequest: crmTokenRequest
  }

  const messagingApi = {
    urls: {
      sms: `${process.env.REACT_APP_MESSAGING_API_URL}/sms/${providerId}`,
      email: `${process.env.REACT_APP_MESSAGING_API_URL}/email/${providerId}`,
      validateEmail: `${process.env.REACT_APP_MESSAGING_API_URL}/validate/email`,
      validateMsisdn: `${process.env.REACT_APP_MESSAGING_API_URL}/validate/msisdn`,
    },
    tokenRequest: messagingTokenRequest
  }

  const geoJsonApi = {
    url: `${process.env.REACT_APP_GEOJSON_API_URL}/${providerId}`,
    tokenRequest: mapTokenRequest
  }


  var [allConfigs, setAllConfigs] = useState([])
  var [config, setConfig] = useState({})

  console.log('providerId in ConfigProvider', providerId)


  useEffect(() => {
    const fetchItems = async () => {
      const tokenResponse = await acquireToken(configsApi.tokenRequest, useRedirectFlow);
      console.log('configs got token', tokenResponse)
      axios
        .get(configsApi.url, {
          headers: { 'Authorization': `Bearer ${tokenResponse.accessToken}` }
        })
        .then(response => {
          setAllConfigs(response.data);
          console.log('configs data in ConfigProvider', response.data);
        });
    }
    if (isAuthenticated) {
      console.log('fetching all config items')
      fetchItems()
    }
  }, [setAllConfigs, isAuthenticated, configsApi.tokenRequest, configsApi.url])

  useEffect(() => {
    const fetchConfig = async () => {
      const tokenResponse = await acquireToken(configsApi.tokenRequest, useRedirectFlow);
      axios
      .get(`${configsApi.url}/${providerId}`, {
        headers: { 'Authorization': `Bearer ${tokenResponse.accessToken}` }
      })
      .then(response => {
        setConfig(response.data);
        console.log('selected config data in ConfigProvider', providerId, response.data);
      });
    }
    if (providerId && isAuthenticated) {
      fetchConfig()
    }
  }, [setConfig, providerId, isAuthenticated, allConfigs, configsApi.tokenRequest, configsApi.url])

  return (
    <ConfigContext.Provider value={{ 
        allConfigs, 
        config,
        customersApi,
        tagsApi,
        readingsApi,
        messagingApi,
        geoJsonApi 
      }}
    >
      {props.children}
    </ConfigContext.Provider>
  );
}

function useConfigState() {
  var context = React.useContext(ConfigContext);
  if (context === undefined) {
    throw new Error("useConfigState must be used within a ConfigProvider");
  }
  return context;
}


export { ConfigProvider, useConfigState };

