import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Grid } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import CustomToolbarSelect from './components/CustomToolbarSelect';
import DeleteDialog from './components/DeleteDialog';
import moment from 'moment';
import { finnishTranslations } from '../../components/TableHelper/finnishTranslations'

import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import { boxMargin } from './components/defaults';
// components
import PageTitle from '../../components/PageTitle/PageTitle';
import MultilineDisplay from '../../components/MultilineDisplay/MultilineDisplay';

// trying to load xlsx on demand (code splitting... https://create-react-app.dev/docs/code-splitting/ and https://reactjs.org/docs/code-splitting.html)
// import XLSX from 'xlsx'

import { acquireToken, useRedirectFlow } from "../../context/UserContext";
import { useConfigState } from "../../context/ConfigProvider";

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    padding: `${theme.spacing(2)}px`,
    // margin: `${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(1)}px`,
    margin: `${theme.spacing(0)}px`,
  },
  box: {
    // width: "80vw"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

export default function Readings(props) {
  // global
  const { readingsApi } = useConfigState();
  const classes = useStyles();

  const [readings, setReadings] = useState([]) // all readings from backend from selected time range
  const [oneSelectedReading, setOneSelectedReading] = useState({}) // selected reading to edit or delete
  const [selectedReadings, setSelectedReadings] = useState([]) // selected readings

  const [query, setQuery] = useState(props.query ?? {
    from: moment().startOf('year').format('YYYY-MM-DD'),
    to: moment().format('YYYY-MM-DD')
  }) // readings query (from, to)

  const [fromFieldValue, setFromFieldValue] = useState(query.from)
  const [toFieldValue, setToFieldValue] = useState(query.to)

  // reading dialogs
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)

  // DELETE: delete selected reading data
  async function deleteData(id) {
    console.log('deleting reading info: ', id);
    const tokenResponse = await acquireToken(readingsApi.tokenRequest, useRedirectFlow);
    // readings api DELETE requires /providerId/readingId
    axios
      .delete(`${readingsApi.url}/${id}`, {
        headers: { 'Authorization': `Bearer ${tokenResponse.accessToken}` }
      })
      .then(response => {
        console.log('data delete completed', response.data);
        // update local state
        const indexToRemove = readings.findIndex(c => c.id === id);
        console.log('index to delete: ', indexToRemove);
        if (~indexToRemove) {
          setOneSelectedReading({});
          setSelectedReadings([]);

          setReadings(prevValue => {
            const updated = [...prevValue];
            updated.splice(indexToRemove, 1);
            return updated;
          });

        }
      });
  }

  useEffect(() => {
    // GET: get readings data
    async function fetchReadingsData() {
      const tokenResponse = await acquireToken(readingsApi.tokenRequest, useRedirectFlow);
      axios
        .get(readingsApi.url, {
          params: query,
          headers: { 'Authorization': `Bearer ${tokenResponse.accessToken}` }
        })
        .then(response => {
          console.log('get readings data promise ready');
          setReadings(response.data);
        });
    }
    fetchReadingsData();
  }, [query, readingsApi.tokenRequest, readingsApi.url])

  const openDeleteDialog = function (dataIndex) {
    console.log('delete data', dataIndex, readings[dataIndex]);
    setOneSelectedReading(readings[dataIndex]);
    setDeleteDialogOpen(true);
  }

  const handleSearchClick = function () {
    setQuery(prevValue => {
      setQuery({ ...prevValue, from: fromFieldValue, to: toFieldValue })
    })
  }

  // readings table columns
  const columns = [
    {
      name: "id",
      label: "Id",
      options: {
        filter: false,
        display: 'excluded'
      }
    },
    {
      name: "customerId",
      label: "astunnus",
      options: {
        filter: false
      }
    },
    {
      name: "customerName",
      label: "Ilmoittajan nimi",
      options: {
        filter: false,
      }
    },
    {
      name: "freetext",
      label: "Vapaa palaute",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          value && <MultilineDisplay>{value}</MultilineDisplay>
        )
      }
    },
    {
      name: "customerEmail",
      label: "Sähköposti",
      options: {
        filter: false
      }
    },
    {
      name: "customerPhone",
      label: "Puhelin",
      options: {
        filter: false
      }
    },
    {
      name: "meterId",
      label: "Mittarin tunnus",
      options: {
        filter: false
      }
    },
    {
      name: "meterLocation",
      label: "Mittarin käyttöpaikka",
      options: {
        filter: false
      }
    },

    {
      name: "lastReadingDate",
      label: "Aikaisempi pvm",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          value ? new Date(value).toLocaleDateString() : ''
        )
      }
    },
    {
      name: "lastReadingValue",
      label: "Aikaisempi kul. (\u33A5)",
      options: {
        filter: false
      }
    },

    {
      name: "readingDate",
      label: "Ilmoitettu pvm",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          value ? new Date(value).toLocaleDateString() : ''
        )
      }
    },
    {
      name: "meterValue",
      label: "Ilmoitettu kul. (\u33A5)",
      options: {
        filter: false
      }
    },

    {
      name: "diff",
      label: "Erotus (\u33A5)",
      options: {
        filter: false
      }
    },

  ];


  const customerIdColumnIndex = columns.map(c => c.name).indexOf('customerId');

  return (
    <>
      <PageTitle title="Vesimittarilukemat" />
      <Grid container spacing={4}>

        <Grid item xs={12}>
          <Paper elevation={1} className={classes.paper}>
            <Box className={classes.box} margin={boxMargin}>
              <h4>Hae vesimittarilukemailmoituksia</h4>
            </Box>
            <Box className={classes.box} margin={boxMargin}>
              <TextField id="fromDate"
                label="alkaen"
                type="date"
                className={classes.textField}
                value={fromFieldValue}
                onChange={(e) => setFromFieldValue(e.target.value)}
              >
              </TextField>

              <TextField id="toDate"
                label="päättyen"
                type="date"
                className={classes.textField}
                value={toFieldValue}
                onChange={(e) => setToFieldValue(e.target.value)}
              >

              </TextField>
            </Box>
            <Box className={classes.box} margin={boxMargin}>
              <Button variant="contained"
                color="primary"
                onClick={handleSearchClick}>
                Hae vesimittarilukemailmoituksia <SearchIcon />
              </Button>
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <MUIDataTable
            data={readings}
            columns={columns}
            options={{
              textLabels: finnishTranslations,
              filter: true,
              filterType: 'checkbox',
              responsive: 'standard',
              selectableRows: 'single',
              selectableRowsHeader: false,
              fixedSelectColumn: false,
              selectableRowsOnClick: true,
              rowsSelected: selectedReadings,
              download: true,
              downloadOptions: {
                filename: `vesimittarilukemat-${moment().format('YYYY-MM-DD')}.csv`,
                filterOptions: {
                  useDisplayedColumnsOnly: true,
                }
              },
              onDownload: (buildHead, buildBody, columns, data) => {
                // see https://reactjs.org/docs/code-splitting.html
                import('xlsx')
                  .then(XLSX => {
                    // console.log('columns to convert to csv', columns);
                    // console.log('data to convert to csv', data);
                    // console.log('download as xlsx')
                    const workbook = XLSX.utils.book_new();
                    const dataSheet = XLSX.utils.aoa_to_sheet([columns.map(c => c.label)])
                    XLSX.utils.sheet_add_aoa(dataSheet, data.map(d => d.data), { origin: 1 })
                    XLSX.utils.book_append_sheet(workbook, dataSheet, "Vesimittarilukemat");
                    XLSX.writeFile(workbook, `vesimittarilukemat-${moment().format('YYYY-MM-DD')}.xlsx`);
                    return false // return false to prevent default MUI Datatable save. XLSX.writeFile handles file download to client
                  })
                  .catch(err => {
                    // Handle failure
                    console.error('failed to load xlsx library. Reverting to default csv download', err);
                    // console.log('download as csv')
                    return "\uFEFF" + buildHead(columns) + buildBody(data);
                  })
                  return false; // prevent default download when import is processing... default (csv) is handled in catch
              },
              onRowSelectionChange: (currentRowSelected, allRowsSelected, rowsSelected) => {
                console.log('onRowsSelect', currentRowSelected, allRowsSelected, rowsSelected);
                if (allRowsSelected) {
                  setSelectedReadings(allRowsSelected.map(i => i.dataIndex))
                } else {
                  setSelectedReadings([]);
                }
              },
              customSort: (data, colIndex, order) => {
                return data.sort((a, b) => {
                  if (colIndex === customerIdColumnIndex) {
                    return (parseInt(a.data[colIndex]) < parseInt(b.data[colIndex]) ? -1 : 1) * (order === 'desc' ? 1 : -1);
                  } else {
                    return (a.data[colIndex] < b.data[colIndex] ? -1 : 1) * (order === 'desc' ? 1 : -1);
                  }
                });
              },
              customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
                <CustomToolbarSelect
                  selectedRows={selectedRows}
                  displayData={displayData}
                  setSelectedRows={setSelectedRows}
                  delete={openDeleteDialog}
                />
              ),
            }}
          />
        </Grid>
      </Grid>

      <DeleteDialog
        selected={oneSelectedReading}
        open={deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        onConfirm={deleteData}
      />
    </>
  );
}
