import L from 'leaflet'

export const orangeIcon = new L.Icon({
    iconUrl: require('./marker-icon-2x-orange.png'),
    shadowUrl: require('./marker-shadow.png'),
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
  });

  export const greenIcon = new L.Icon({
    iconUrl: require('./marker-icon-2x-green.png'),
    shadowUrl: require('./marker-shadow.png'),
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
  });