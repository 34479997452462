import React, { useState, useEffect, useRef } from "react"
import { withLeaflet } from "react-leaflet"
import Control from "react-leaflet-control"
import axios from 'axios';

// styles
import "./styles.css";

const fmiUrls = {
  stations: 'https://opendata.fmi.fi/wfs/fin?service=WFS&version=2.0.0&request=getFeature&storedquery_id=fmi::ef::stations',
  observations: function (station, startTime, endTime) {
    return `https://opendata.fmi.fi/wfs/fin?service=WFS&version=2.0.0&request=getFeature&storedquery_id=fmi::observations::weather::timevaluepair&geoid=${station.geoid}&parameters=temperature,humidity,precipitation1h&timestep=60&maxlocations=1&starttime=${startTime}&endtime=${endTime}`
  },
  forecast: function (station, endTime) {
    return `https://opendata.fmi.fi/wfs/fin?service=WFS&version=2.0.0&request=getFeature&storedquery_id=fmi::forecast::hirlam::surface::point::timevaluepair&geoid=${station.geoid}&endtime=${endTime}&parameters=WeatherSymbol3`
  }
}

const weatherStationsKey = 'weatherstations'
const weatherUpdateCoolOffDefault = 10000

function WeatherControl(props) {
  const { position, coolOff } = props
  const { map } = props.leaflet

  const weatherUpdateCoolOff = coolOff ?? weatherUpdateCoolOffDefault

  // console.log('weatherUpdateCoolOff', weatherUpdateCoolOff)

  const [stations, _setStations] = useState([])
  const [latestWeatherTime, _setLatestWeatherTime] = useState(Date.now() - weatherUpdateCoolOff)
  const [latestWeather, _setLatestWeather] = useState({})
  const [latestForecast, _setLatestForecast] = useState({})

  // accessing component local state from map event listener via ref inspired by https://medium.com/geographit/accessing-react-state-in-event-listeners-with-usestate-and-useref-hooks-8cceee73c559
  const stationsRef = useRef(stations)
  const latestWeatherTimeRef = useRef(latestWeatherTime)
  const latestWeatherRef = useRef(latestWeather)
  const latestForecastRef = useRef(latestForecast)

  const setStations = data => {
    stationsRef.current = data
    _setStations(data)
  }

  const setLatestWeatherTime = data => {
    latestWeatherTimeRef.current = data
    _setLatestWeatherTime(data)
  }

  const setLatestWeather = data => {
    latestWeatherRef.current = data
    _setLatestWeather(data)
  }

  const setLatestForecast = data => {
    latestForecastRef.current = data
    _setLatestForecast(data)
  }

  useEffect(() => {
    const getStations = function () {
      console.log("Fetching weather stations")

      const localStationsString = localStorage.getItem(weatherStationsKey)
      if (localStationsString) {
        const localStations = JSON.parse(localStationsString)
        if (localStations && localStations.exp > Date.now()) {
          console.log('setting weather stations from localStorage', localStations.stations)
          setStations(localStations.stations)
          return
        }
      }

      axios
        .get(fmiUrls.stations, { responseType: 'document' })
        .then(response => {
          const data = response.data
          // console.log('weather data', data)

          const facilities = data.getElementsByTagName('ef:EnvironmentalMonitoringFacility')

          const stationsArray = []
          for (let index = 0; index < facilities.length; index++) {
            const element = facilities[index];
            const coord = element.getElementsByTagName('gml:pos')[0].textContent.split(' ')
            const geoid = getFirstElementByAttribute(element.getElementsByTagName('gml:name'), 'codeSpace', 'http://xml.fmi.fi/namespace/locationcode/geoid')
            if (geoid) {
              const station = {
                name: element.getElementsByTagName('ef:name')[0].textContent,
                geoid: geoid.textContent,
                lat: coord[0],
                lng: coord[1]
              }
              stationsArray.push(station)
            }
          }
          console.log('stations', stationsArray)
          localStorage.setItem(weatherStationsKey, JSON.stringify({ exp: getExpTime(7), stations: stationsArray }))
          setStations(stationsArray)
        })
    }

    getStations()
  }, [_setStations])

  useEffect(() => {
    function handlePopupOpen(e) {
      // console.log('handlePopupOpen: latestWeatherTime, now, e', latestWeatherTime, Date.now(), e)
      if (latestWeatherTimeRef.current + weatherUpdateCoolOff > Date.now()) {
        // console.log('no weather update now < cooloff', Date.now(), latestWeatherTime + weatherUpdateCoolOff)
        // do not update weather info if last update was less than weatherUpdateCoolOff milliseconds ago
        return
      }
      getWeather(e)
    }
    // console.log('bind map on popupopen at', latestWeatherTime)
    map.on("popupopen", handlePopupOpen)

    return function unMountWeather() {
      // console.log('unbind map popupopen at', latestWeatherTime)
      map.off("popupopen", handlePopupOpen)
    }
  }, [])

  function getExpTime(days) {
    const ms = new Date().getTime() + days * 86400000 // 86400000 == milliseconds per day (24 h)
    return ms
  }

  function getFirstElementByAttribute(elements, attr, attrValue) {
    for (let index = 0; index < elements.length; index++) {
      const element = elements[index];
      if (element.getAttribute(attr) === attrValue) {
        return element
      }
    }
    // console.log('getFirstElementByAttribute: element not found', attr, attrValue, elements)
  }

  const getWeather = function (e) {
    // console.log("Fetching weather on", e)
    //find target coords
    let latlng;
    if (e != null) {
      latlng = e.popup._source._popup._latlng
      // console.log('latlng from popup', latlng)
    } else {
      // latlng = {
      //   lat: defaultView[0],
      //   lon: defaultView[1]
      // }
      latlng = map.getCenter()
      // console.log('latlng from map center', latlng)
    }

    //find closest weather station
    let closest = {};
    // console.log('finding closes weather station from ', stationsRef.current)
    for (let i = 0; i < stationsRef.current.length; i++) {
      //check if new coords are closer than old ones
      if (checkIfClosest(latlng.lat, latlng.lng, stationsRef.current[i].lat, stationsRef.current[i].lng, closest.lat, closest.lng)) {
        closest = stationsRef.current[i]
      }
    }
    // console.log('closest', closest)
    if (closest.name === undefined) {
      console.log("Weather could not be fetched.")
      return;
    }
    setLatestWeatherTime(Date.now())
    console.log("Closest station: " + closest.name)
    let lastHour = new Date();
    lastHour.setHours(lastHour.getHours() - 1);
    lastHour = lastHour.toISOString();
    let thisHour = new Date();
    thisHour = thisHour.toISOString();
    let nextHour = new Date();
    nextHour.setHours(nextHour.getHours() + 1);
    nextHour = nextHour.toISOString();

    const observation = {
      city: closest.name,
      timestamp: new Date(),
      values: []
    }
    // $.get("https://opendata.fmi.fi/wfs/fin?service=WFS&version=2.0.0&request=getFeature&storedquery_id=fmi::observations::weather::timevaluepair&geoid=" + closest.geoid + "&parameters=temperature,humidity,precipitation1h&timestep=60&maxlocations=1&starttime=" + lastHour + "&endtime=" + thisHour + "&")
    axios.get(fmiUrls.observations(closest, lastHour, thisHour), { responseType: 'document' })
      .then(response => {
        const data = response.data
        // console.log('weather observations from', closest.name, data)
        if (data) {
          const values = data.getElementsByTagName("wml2:value")
          // console.log('values: ', values);
          const timestamps = data.getElementsByTagName("wml2:time")
          // console.log('timestamps: ', timestamps);


          if (values && values.length > 0 && timestamps && timestamps.length > 0) {
            // map observations
            observation.timestamp = new Date(timestamps[0].textContent)
            if (values[0] && values[0].textContent !== 'NaN') {
              observation.values.push({ name: 'Lämpötila', value: values[0].textContent, unit: '°C' })
            }
            if (values[1] && values[1].textContent !== 'NaN') {
              observation.values.push({ name: 'Suht. kosteus', value: values[1].textContent, unit: '%RH' })
            }
            if (values[2] && values[2].textContent !== 'NaN') {
              observation.values.push({ name: 'Sademäärä', value: values[2].textContent, unit: 'mm' })
            }
          }
        }
        setLatestWeather(observation)
      });
    // $.get("https://opendata.fmi.fi/wfs/fin?service=WFS&version=2.0.0&request=getFeature&storedquery_id=fmi::forecast::hirlam::surface::point::timevaluepair&geoid=" + closest.geoid + "&endtime=" + nextHour + "&parameters=WeatherSymbol3")
    const forecast = {
      timestamp: new Date(),
      city: closest.name,
    }
    axios.get(fmiUrls.forecast(closest, nextHour), { responseType: 'document' })
      .then(response => {
        const data = response.data
        // console.log('weather forecast from', closest, data)
        if (data) {
          const result = data.getElementsByTagName("wml2:value")
          const weatherCode = Math.round(result[0].textContent)
          forecast.code = weatherCode
        }
        //show different icon depending on the code
        // $("#weatherIcon").html('<img src="modules/map/symbols/' + weatherCode + '.svg" class="weatherIcon">')
      });

    setLatestForecast(forecast)
  }

  function checkIfClosest(origLat, origLng, newLat, newLng, cloLat, cloLng) {
    // console.log('check latlng', origLat, origLng, newLat, newLng, cloLat, cloLng)
    if (cloLat === undefined) {
      //if there isn't closest coord yet, the new one is closest
      return true;
    }
    else {
      //calculate distance and compare
      const newDiff = Math.pow(newLat - origLat, 2) + Math.pow(newLng - origLng, 2);
      const oldDiff = Math.pow(cloLat - origLat, 2) + Math.pow(cloLng - origLng, 2);
      if (newDiff < oldDiff) {
        return true;
      }
      else {
        return false;
      }
    }
  }

  return (
    <>
      {latestWeather.city &&
        <Control position={position ?? 'bottonright'} className="leaflet-bar">
          <div title="Lähimmän sääaseman havainnot" className="shadow-sm p-2 bg-white rounded weather-div">
            <div className="weatherIcon">
              {latestForecast && latestForecast.code &&
                <img src={`${process.env.PUBLIC_URL}/img/map/symbols/${latestForecast.code}.svg`} className="weatherIcon" alt="Sääikoni" />}
            </div>
            <ul className="list-unstyled weather">
              <li><strong>{latestWeather.city}</strong></li>
              <li>{latestWeather.timestamp.toLocaleDateString()} {latestWeather.timestamp.toLocaleTimeString()}</li>
              {latestWeather.values.map(v => <li key={v.name}>{v.name} {v.value}{v.unit}</li>)}
            </ul>
          </div>
        </Control>
      }
    </>
  );
}

export default withLeaflet(WeatherControl);