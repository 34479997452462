import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Button, LinearProgress, FormLabel, Divider } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { TextField, CheckboxWithLabel } from 'formik-material-ui';
import Paper from "@material-ui/core/Paper";
import Box from '@material-ui/core/Box';
import { boxMargin } from './defaults';


// use Formik for form state management, https://jaredpalmer.com/formik/
// use Yup for model validation, https://www.npmjs.com/package/yup


const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(5)}px ${theme.spacing(5)}px ${theme.spacing(5)}px`,
    margin: `${theme.spacing(5)}px ${theme.spacing(5)}px ${theme.spacing(5)}px`,
  },
  box: {
    // width: "80vw"
  },
  editForm: {
    width: "80vw"
  }  
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DeleteDialog(props) {
  const classes = useStyles();
  const { open, setOpen, onConfirm, selectedCustomer } = props;

  console.log('Delete dialog load');

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              <span>Poista asiakastieto?</span>
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
              Sulje poistamatta
            </Button>
          </Toolbar>
        </AppBar>

        <Paper elevation={1} className={classes.paper}>
          <Formik
            initialValues={selectedCustomer}
            onSubmit={(values, { isSubmitting, setSubmitting }) => {
              if (isSubmitting) {
                return;
              }
              setSubmitting(false);
              setOpen(false);
              onConfirm(selectedCustomer.id, selectedCustomer.cid);
            }}
          >
            {({ submitForm, isSubmitting, values }) => (
              <Form className={classes.editForm}>
                <Box className={classes.box} margin={boxMargin}>
                  <Field type="text" name="cid" label="Asiakas id" component={TextField} fullWidth disabled />
                </Box>
                <Box className={classes.box} margin={boxMargin}>
                  <Field type="text" name="name" label="Nimi" component={TextField} fullWidth disabled />
                </Box>
                <Box className={classes.box} margin={boxMargin}>
                  <Field type="email" name="email" label="Sähköposti" component={TextField} fullWidth disabled />
                  <ErrorMessage name="email" component="div" />
                </Box>
                <Box className={classes.box} margin={boxMargin}>
                  <Field type="tel" name="mobile" label="Puhelin" component={TextField} fullWidth disabled />
                  <ErrorMessage name="mobile" component="div" />
                </Box>
                <Box className={classes.box} margin={boxMargin}>
                  <Field type="text" name="info" label="Tietoja" component={TextField} fullWidth multiline disabled />
                </Box>

                <Divider />

                <Box className={classes.box} margin={boxMargin}>
                  <FormLabel>Katuosoite</FormLabel>
                </Box>
                <Box className={classes.box} margin={boxMargin + 2}>
                  <Field type="text" name="address.line1" label="Katusoite" component={TextField} fullWidth disabled />
                  <Field type="text" name="address.line2" label="Osoiterivi 2" component={TextField} fullWidth disabled />
                  <Field type="text" name="address.postalCode" label="Postinumero" component={TextField} fullWidth disabled />
                  <Field type="text" name="address.postalAddress" label="Postiosoite" component={TextField} fullWidth disabled />
                  <Field type="text" name="address.country" label="Maa" component={TextField} fullWidth disabled />
                </Box>

                <Divider />

                <Box className={classes.box} margin={boxMargin}>
                  <FormLabel>Laskutusosoite</FormLabel>
                </Box>
                <Box className={classes.box} margin={boxMargin}>
                  <Field type="checkbox" name="billingAddress.sameAsAddress" component={CheckboxWithLabel} Label={{ label: 'Käytä katuosoitetta' }} disabled />
                </Box>
                <Box className={classes.box} margin={boxMargin + 2} hidden={values.billingAddress?.sameAsAddress ?? false}>
                  <Field type="text" name="billingAddress.line1" label="Osoiterivi 1" component={TextField} fullWidth disabled />
                  <Field type="text" name="billingAddress.line2" label="Osoiterivi 2" component={TextField} fullWidth disabled />
                  <Field type="text" name="billingAddress.postalCode" label="Postinumero" component={TextField} fullWidth disabled />
                  <Field type="text" name="billingAddress.postalAddress" label="Postiosoite" component={TextField} fullWidth disabled />
                  <Field type="text" name="billingAddress.country" label="Maa" component={TextField} fullWidth disabled />
                </Box>

                <Divider />

                {isSubmitting && <LinearProgress />}
                <Box className={classes.box} margin={boxMargin}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={isSubmitting}
                  >
                    Poista
              </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Paper>
      </Dialog>
    </div>
  );
}
