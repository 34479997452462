import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Grid } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import CustomToolbarSelect from './components/CustomToolbarSelect';
import CustomToolbar from "./components/CustomToolbar";
import EditDialog from "./components/EditDialog";
import DeleteDialog from "./components/DeleteDialog";
import AddDialog from "./components/AddDialog";
import { finnishTranslations } from '../../components/TableHelper/finnishTranslations'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

// import { makeStyles } from '@material-ui/core/styles';

// components
import PageTitle from '../../components/PageTitle/PageTitle';
import MultilineDisplay from '../../components/MultilineDisplay/MultilineDisplay';

import { useUserState, acquireToken, useRedirectFlow } from "../../context/UserContext";
import { useConfigState } from "../../context/ConfigProvider";

// const useStyles = makeStyles((theme) => ({
//   container: {
//     display: 'flex',
//     flexWrap: 'wrap',
//   },
//   paper: {
//     marginTop: theme.spacing(2),
//     display: "flex",
//     flexDirection: "column",
//     alignItems: "left",
//     padding: `${theme.spacing(2)}px`,
//     margin: `${theme.spacing(0)}px`,
//   },
//   box: {
//     // width: "80vw"
//   },
//   textField: {
//     marginLeft: theme.spacing(1),
//     marginRight: theme.spacing(1),
//     width: 200,
//   },
// }));

export default function Tags(props) {
  // global
  // const userDispatch = useUserDispatch();
  const userState = useUserState();
  const { tagsApi } = useConfigState();
  // const classes = useStyles();

  // const [providerId, setProviderId] = useState('269') // currently selected provider
  const providerId = userState.providerId;
  console.log('Tags with providerId', userState.providerId)

  const [tags, setTags] = useState([]) // all tags from backend
  const [oneSelectedTag, setOneSelectedTag] = useState({}) // selected tag to edit or delete
  const [selectedTags, setSelectedTags] = useState([]) // selected tags

  // dialogs
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [editDialogOpen, setEditDialogOpen] = useState(false)
  const [addDialogOpen, setAddDialogOpen] = useState(false)

  // const tagsApiUri = `${process.env.REACT_APP_TAGS_API_URL}/${providerId}`;



  // initial tags load
  useEffect(() => {
    // GET: get tags data
    async function fetchTagsData() {
      const tokenResponse = await acquireToken(tagsApi.tokenRequest, useRedirectFlow);
      axios
        .get(tagsApi.url, {
          headers: { 'Authorization': `Bearer ${tokenResponse.accessToken}` }
        })
        .then(response => {
          console.log('get tags data promise ready');
          setTags(response.data);
        });
    }
    fetchTagsData();
  }, [setTags, tagsApi.tokenRequest, tagsApi.url])

  // DELETE: delete selected tag data
  async function deleteData(id, tid) {
    console.log('deleting tag info: ', id, tid);
    // tags api DELETE requires /providerId/tagId
    const tokenResponse = await acquireToken(tagsApi.tokenRequest, useRedirectFlow);
    axios
      .delete(`${tagsApi.url}/${tid}`, {
        headers: { 'Authorization': `Bearer ${tokenResponse.accessToken}` }
      })
      .then(response => {
        console.log('data delete completed', response.data);
        // update local state
        const indexToRemove = tags.findIndex(c => c.id === id);
        console.log('index to delete: ', indexToRemove);
        if (~indexToRemove) {
          setOneSelectedTag({});
          setSelectedTags([]);

          setTags(prevValue => {
            const updated = [...prevValue];
            updated.splice(indexToRemove, 1);
            return updated;
          });

        }
      });
  }

  // PUT: save updated tag data
  async function updateData(id, tid, values) {
    console.log('updating tag info: ', tid, values);
    // values MUST be a representation of tag object with all properties
    // PUT will replace the data document in repository!

    const tokenResponse = await acquireToken(tagsApi.tokenRequest, useRedirectFlow);
    // tags api PUT requires /providerId/tagId
    axios
      .put(`${tagsApi.url}/${tid}`,
        values,
        {
          headers: { 'Authorization': `Bearer ${tokenResponse.accessToken}` }
        })
      .then(response => {
        console.log('data put completed', response.data);
        // update local state
        const indexToReplace = tags.findIndex(c => c.id === id);
        if (~indexToReplace) {
          setTags(prevValue => {
            const updated = [...prevValue];
            updated.splice(indexToReplace, 1, values);
            return updated;
          });
          setOneSelectedTag({});
        }
      });
  }

  // POST: save new tag data
  async function addData(values) {
    values['pid'] = providerId;
    values['id'] = `${providerId}-${values.tid}`;
    values['props'] = [];

    console.log('adding new tag info: ', values);
    const tokenResponse = await acquireToken(tagsApi.tokenRequest, useRedirectFlow);
    axios
      .post(tagsApi.url,
        values,
        {
          headers: { 'Authorization': `Bearer ${tokenResponse.accessToken}` }
        })
      .then(response => {
        console.log('data add completed', response.data);
        // update local state
        setTags(prevValue => {
          const updated = [...prevValue];
          updated.push(response.data);
          return updated;
        });
      });
  }

  // handle open dialogs
  const openEditDialog = function (dataIndex) {
    console.log('edit data', dataIndex, tags[dataIndex]);
    setOneSelectedTag(tags[dataIndex]);
    setEditDialogOpen(true);
  }

  const openAddDialog = function () {
    console.log('add data');
    setAddDialogOpen(true);
  }

  const openDeleteDialog = function (dataIndex) {
    console.log('delete data', dataIndex, tags[dataIndex]);
    setOneSelectedTag(tags[dataIndex]);
    setDeleteDialogOpen(true);
  }


  // tags table columns
  const columns = [
    {
      name: "id",
      label: "Id",
      options: {
        filter: false,
        display: 'excluded'
      }
    },
    {
      name: "tid",
      label: "Id",
      options: {
        filter: false,
        display: 'false'
      }
    },
    {
      name: "dn",
      label: "Tunniste",
      options: {
        filter: false,
      }
    },
    {
      name: "role",
      label: "Käyttötarkoitus",
      options: {
        filter: true,
      }
    },
    {
      name: "info",
      label: "Tietoja tunnisteesta",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          value && <MultilineDisplay>{value}</MultilineDisplay>
        )
      }
    },
    {
      name: "props",
      label: "Ominaisuudet",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          // TODO: fix tag properties display -> should different props be displayed in their own columns?
          value && value.length > 0 ?
            <List component="ul">
              {value.map(v => (<ListItem key={`p-${v.name}`}><ListItemText primary={v.value} secondary={v.name} /></ListItem>))}
            </List>
            : ''
        )
      }
    },
  ];


  return (
    <>
      <PageTitle title="Tunnisteet" />
      <Grid container spacing={4}>

        <Grid item xs={12}>
          <MUIDataTable
            data={tags}
            columns={columns}
            options={{
              textLabels: finnishTranslations,
              filter: true,
              filterType: 'checkbox',
              responsive: 'standard',
              selectableRows: 'single',
              selectableRowsHeader: false,
              fixedSelectColumn: false,
              selectableRowsOnClick: true,
              rowsSelected: selectedTags,
              download: false,
              onRowSelectionChange: (currentRowSelected, allRowsSelected, rowsSelected) => {
                console.log('onRowsSelect', currentRowSelected, allRowsSelected, rowsSelected);
                if (allRowsSelected) {
                  setSelectedTags(allRowsSelected.map(i => i.dataIndex))
                } else {
                  setSelectedTags([]);
                }
              },
              customToolbar: () => {
                return (<CustomToolbar handleAddClick={openAddDialog} />);
              },
              customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
                <CustomToolbarSelect
                  selectedRows={selectedRows}
                  displayData={displayData}
                  setSelectedRows={setSelectedRows}
                  edit={openEditDialog}
                  delete={openDeleteDialog}
                />
              ),
            }}
          />
        </Grid>
      </Grid>

      <EditDialog
        selected={oneSelectedTag}
        open={editDialogOpen}
        setOpen={setEditDialogOpen}
        onSave={updateData}
        roles={[...new Set(tags.map(t => t.role).filter(t => t))]}
      />
      <AddDialog
        open={addDialogOpen}
        setOpen={setAddDialogOpen}
        onSave={addData}
      />
      <DeleteDialog
        selected={oneSelectedTag}
        open={deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        onConfirm={deleteData}
      />
    </>
  );
}
