import React from "react";
import {
  Route,
  Switch,
  withRouter,
} from "react-router-dom";
import classnames from "classnames";

// styles
import useStyles from "./styles";

// components
import Header from "../Header";
import Sidebar from "../Sidebar";

// pages
import Dashboard from "../../pages/dashboard";
import Notifications from "../../pages/notifications";
// import Typography from "../../pages/typography";
// import Maps from "../../pages/maps";
// import Tables from "../../pages/tables";
// import Icons from "../../pages/icons";
// import Charts from "../../pages/charts";
import Customers from "../../pages/customers";
import Readings from "../../pages/readings";
import Tags from "../../pages/tags";
import Messages from "../../pages/messages";
import Help from "../../pages/help";
import Faq from "../../pages/faq";

// context
import { useLayoutState } from "../../context/LayoutContext";
import { useUserState } from "../../context/UserContext";

import MeasCharts from "../../pages/charts/MeasCharts";

function Layout(props) {
  const classes = useStyles();

  // global
  const layoutState = useLayoutState();
  const userState = useUserState();

  const hasProviderId = !!userState.providerId;
  console.log('Layout with providerId', userState.providerId)

  return (
    <div className={classes.root}>
      <>
        <Header history={props.history} />
        <Sidebar />
        <div
          className={classnames(classes.content, {
            [classes.contentShift]: layoutState.isSidebarOpened,
          })}
        >
          <div className={classes.fakeToolbar} />
          <Switch>
            <Route path="/app/dashboard" component={Dashboard} />
            {/* <Route path="/app/typography" component={Typography} /> */}
            {/* <Route path="/app/tables" component={Tables} /> */}
            {hasProviderId && <Route path="/app/customers" component={Customers} />}
            {hasProviderId && <Route path="/app/messages" component={Messages} />}
            {hasProviderId && <Route path="/app/readings" component={Readings} />}
            {hasProviderId && <Route path="/app/tags" component={Tags} />}
            <Route path="/app/charts" component={MeasCharts} />
            <Route path="/app/notifications" component={Notifications} />
            {/* <Route
              exact
              path="/app/ui"
              render={() => <Redirect to="/app/ui/icons" />}
            /> */}
            {/* <Route path="/app/ui/maps" component={Maps} /> */}
            {/* <Route path="/app/ui/icons" component={Icons} /> */}
            {/* <Route path="/app/ui/charts" component={Charts} /> */}
            <Route path="/app/help" component={Help} />
            <Route path="/app/faq" component={Faq} />
            </Switch>
        </div>
      </>
    </div>
  );
}

export default withRouter(Layout);
