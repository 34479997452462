import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

// components
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";

import moment from 'moment';


export default function MeasCharts(props) {
  var theme = useTheme();

  // const [hasError, setErrors] = useState(false)
  const [meas, setMeas] = useState([])
  const [time, setTime] = useState({})

  async function fetcData() {
    const d = new Date();
    const today = moment(d).format('YYYY-MM-DD');
    setTime(d.toLocaleDateString());
    const uri = `https://preventos-measurements.azurewebsites.net/Measurements/PK107-weatherdatafordemo?obj=Kuopio&tag=Opistotie&tzi=FLE Standard Time&date=${today}`;
    // console.log(uri);
    fetch(uri)
      .then(res => res.json())
      .then(res => {
        // res contains measurement where the newest meas is first -> reverse to get meas in ascending date order
        const reversed = res.reverse();
        const mapped = reversed.map(m => {
          const n = { 
            time: moment(new Date(m.timestamp)).format('HH:mm') 
          };
          m.data.forEach(d => { n[d.t] = d.v });
          return n;
        })
        setMeas(mapped);
      })
      .catch(err => {
        // setErrors(err);
        console.log('error reding data: ', err);
      });
  }


  useEffect(() => {
    fetcData();
  }, []);

  // local
  // var [activeIndex, setActiveIndexId] = useState(0);

  return (
    <>
      <PageTitle title={`Mittaukset - ${time}`} />
      <Grid container spacing={4}>
        <Grid item xs={12} md={8}>
          <Widget title="Säätila" noBodyPadding upperTitle>
            <ResponsiveContainer width="100%" height={450}>
              <LineChart
                width={500}
                height={300}
                data={meas}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="time" />
                <YAxis yAxisId='left' dataKey="temperature" label={{ value: 'Lämpötila', angle: -90, position: 'insideLeft' }} unit=' &#176;C' />
                <YAxis padding={{ left: 20 }} yAxisId='right' dataKey="rainIntensity" orientation='right' label={{ value: 'Sateen voimakkuus', angle: 90, position: 'insideRight' }} unit=' mm/h' />
                <Tooltip />
                <Legend />
                <Line
                  yAxisId='left'
                  type="monotone"
                  dataKey="temperature"
                  stroke={theme.palette.primary.main}
                  activeDot={{ r: 8 }}
                  dot={false}
                />
                <Line
                  yAxisId='right'
                  type="monotone"
                  dataKey="rainIntensity"
                  stroke={theme.palette.secondary.main}
                  dot={false}
                />
              </LineChart>
            </ResponsiveContainer>
          </Widget>
        </Grid>
      </Grid>
    </>
  );
}

// ################################################################

// function renderActiveShape(props) {
//   var RADIAN = Math.PI / 180;
//   var {
//     cx,
//     cy,
//     midAngle,
//     innerRadius,
//     outerRadius,
//     startAngle,
//     endAngle,
//     fill,
//     payload,
//     percent,
//     value,
//   } = props;
//   var sin = Math.sin(-RADIAN * midAngle);
//   var cos = Math.cos(-RADIAN * midAngle);
//   var sx = cx + (outerRadius + 10) * cos;
//   var sy = cy + (outerRadius + 10) * sin;
//   var mx = cx + (outerRadius + 30) * cos;
//   var my = cy + (outerRadius + 30) * sin;
//   var ex = mx + (cos >= 0 ? 1 : -1) * 22;
//   var ey = my;
//   var textAnchor = cos >= 0 ? "start" : "end";

//   return (
//     <g>
//       <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
//         {payload.name}
//       </text>
//       <Sector
//         cx={cx}
//         cy={cy}
//         innerRadius={innerRadius}
//         outerRadius={outerRadius}
//         startAngle={startAngle}
//         endAngle={endAngle}
//         fill={fill}
//       />
//       <Sector
//         cx={cx}
//         cy={cy}
//         startAngle={startAngle}
//         endAngle={endAngle}
//         innerRadius={outerRadius + 6}
//         outerRadius={outerRadius + 10}
//         fill={fill}
//       />
//       <path
//         d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
//         stroke={fill}
//         fill="none"
//       />
//       <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
//       <text
//         x={ex + (cos >= 0 ? 1 : -1) * 12}
//         y={ey}
//         textAnchor={textAnchor}
//         fill="#333"
//       >{`PV ${value}`}</text>
//       <text
//         x={ex + (cos >= 0 ? 1 : -1) * 12}
//         y={ey}
//         dy={18}
//         textAnchor={textAnchor}
//         fill="#999"
//       >
//         {`(Rate ${(percent * 100).toFixed(2)}%)`}
//       </text>
//     </g>
//   );
// }
