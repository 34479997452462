import React, { useState, useEffect } from "react"
import { useUserState, acquireToken, useRedirectFlow } from "./UserContext"
import { useConfigState } from "./ConfigProvider"
import axios from 'axios';

const CustomerContext = React.createContext()

function CustomerProvider(props) {
  // global
  const { providerId, isAuthenticated } = useUserState()
  const { customersApi } = useConfigState()
  // const customersApiUri = `${process.env.REACT_APP_CUSTOMERS_API_URL}/${providerId}`

  console.log('providerId in CustomerProvider with scopes', providerId, customersApi.tokenRequest)

  const [customers, setCustomers] = useState([])

  useEffect(() => {
    const fetchItems = async () => {
      console.log('starting customers fetch with providerId', providerId)
      const tokenResponse = await acquireToken(customersApi.tokenRequest, useRedirectFlow);
      console.log('got token response', tokenResponse)
      axios
        .get(customersApi.url, {
          headers: { 'Authorization': `Bearer ${tokenResponse.accessToken}` }
        })
        .then(response => {
          setCustomers(response.data);
          console.log('customers data in CustomerProvider', response.data);
        });
    }
    if (providerId && isAuthenticated) {
      // console.log('call fetchItems() providerId, isAuthenticated', providerId, isAuthenticated)
      fetchItems()
    }
  }, [setCustomers, providerId, isAuthenticated, customersApi.tokenRequest, customersApi.url])

  // DELETE: delete selected customer
  const deleteCustomer = async (id, cid) => {
    console.log('deleting customer info: ', id, cid);
    const tokenResponse = await acquireToken(customersApi.tokenRequest, useRedirectFlow);
    // customers api DELETE requires /providerId/customerId
    axios
      .delete(`${customersApi.url}/${cid}`, {
        headers: { 'Authorization': `Bearer ${tokenResponse.accessToken}` }
      })
      .then(response => {
        console.log('data delete completed', response.data);
        // update local state
        const indexToRemove = customers.findIndex(c => c.id === id);
        console.log('index to delete: ', indexToRemove);
        if (~indexToRemove) {
          setCustomers(prevValue => {
            const updatedCustomers = [...prevValue];
            updatedCustomers.splice(indexToRemove, 1);
            return updatedCustomers;
          });
        }
        // return { success: true };
      })
      .catch(error => {
        console.error(error)
        // return { success: false, message: error.message, error: error };
      });
  }

  // PUT: edit customer data
  const editCustomer = async (id, cid, customer, createBackup) => {
    console.log('updating customer info: ', cid, customer, createBackup);
    // values MUST be a representation of customer object with all properties
    // PUT will replace the data document in repository!

    // The API can create a backup of data to be updated. The backup is requested with query param 'backup'.
    // backup param does not need a value

    const tokenResponse = await acquireToken(customersApi.tokenRequest, useRedirectFlow);
    // customers api PUT requires /providerId/customerId
    axios
      .put(createBackup ? `${customersApi.url}/${cid}?backup` : `${customersApi.url}/${cid}`,
        customer,
        {
          headers: { 'Authorization': `Bearer ${tokenResponse.accessToken}` }
        })
      .then(response => {
        console.log('data put completed', response.data);
        // update local state
        const indexToReplace = customers.findIndex(c => c.id === id);
        if (~indexToReplace) {
          setCustomers(prevValue => {
            const updatedCustomers = [...prevValue];
            updatedCustomers.splice(indexToReplace, 1, customer);
            return updatedCustomers;
          });
        }
        // return { success: true }
      })
      .catch(error => {
        console.error(error)
        // return { success: false, message: error.message, error: error }
      });
  }

  // POST: save new customer data
  const addCustomer = async (customer) => {
    customer['pid'] = providerId;
    customer['id'] = `${providerId}-${customer.cid}`;
    customer['location'] = [];
    customer['meters'] = [];

    console.log('adding new customer info: ', customer);
    const tokenResponse = await acquireToken(customersApi.tokenRequest, useRedirectFlow);
    axios
      .post(customersApi.url,
        customer,
        {
          headers: { 'Authorization': `Bearer ${tokenResponse.accessToken}` }
        })
      .then(response => {
        console.log('data add completed', response.data);
        // update local state
        setCustomers(prevValue => {
          const updatedCustomers = [...prevValue];
          updatedCustomers.push(response.data);
          return updatedCustomers;
        });
        // return { success: true }
      })
      .catch(error => {
        console.error(error)
        // return { success: false, message: error.message, error: error }
      });
  }

  return (
    <CustomerContext.Provider value={{ customers, deleteCustomer, editCustomer, addCustomer }}>
      {props.children}
    </CustomerContext.Provider>
  );
}

function useCustomerState() {
  var context = React.useContext(CustomerContext);
  if (context === undefined) {
    throw new Error("useCustomerState must be used within a CustomerProvider");
  }
  return context;
}


export { CustomerProvider, useCustomerState };

