import React, { useState, useEffect, useRef } from 'react'
import { Map, Popup } from 'react-leaflet'
import DefaultLayers from './components/DefaultLayers'
import Control from "react-leaflet-control"
import ToggleButton from '@material-ui/lab/ToggleButton'
import EditLocationIcon from '@material-ui/icons/EditLocationOutlined'
import OpenMarker from './components/OpenMarker';
import L from 'leaflet'
import './css/positionSelect.css'
import MapboxWordmark from './components/MapboxWordmark'
import { useConfigState } from "../../context/ConfigProvider";

export default function LocationSelectMap(props) {
  const { location, name, onPositionChange } = props

  const { config } = useConfigState();

  const mapZoomAtStart = 13

  const [clickToSetPosition, setClickToSetPosition] = useState(false)

  // Center map to Kuopio when no locations is reveived as props
  const [position, setPosition] = useState(location ? { ...location, zoom: mapZoomAtStart + 2 } : config?.map?.startPosition ?? {
    lat: 62.89238,
    lng: 27.67703,
    zoom: mapZoomAtStart
  })

  // leaflet map instance.
  const map = useRef(null);

  function onMapClick(e) {
    console.log('map clicked at ', e)
    if (clickToSetPosition) {
      onPositionChange(e.latlng)
      setPosition({ ...location, ...e.latlng })
    }
  }

  function handleDragEnd(e) {
    console.log('handling drag end', e)
    console.log('new position', e.target._latlng)
    onPositionChange(e.target._latlng)
    setPosition({ ...location, ...e.target._latlng })
  }

  useEffect(() => {
    console.log('changing map cursor style', clickToSetPosition, map)
    if (map.current) {
      if (clickToSetPosition) {
        L.DomUtil.addClass(map.current.container, 'crosshair-cursor-enabled')
      } else {
        L.DomUtil.removeClass(map.current.container, 'crosshair-cursor-enabled')
      }
    }
  }, [clickToSetPosition])

  return (
    <Map ref={map}
      center={[position.lat, position.lng]}
      zoom={position.zoom}
      onClick={(e) => onMapClick(e)}
    >
      <DefaultLayers position='topright' />

      <OpenMarker position={position} draggable={true} ondragend={handleDragEnd}>
        <Popup autoClose={false}>{name}</Popup>
      </OpenMarker>

      <Control position='topleft' className="leaflet-bar">
        <ToggleButton
          className="leaflet-bar-part leaflet-bar-part-single"
          title={clickToSetPosition ? "Päätä paikan valinta karttaa klikkaamalla" : "Aktivoi paikan valinta karttaa klikkaamalla"}
          value="check"
          selected={clickToSetPosition}
          onChange={() => setClickToSetPosition(!clickToSetPosition)}>

          <EditLocationIcon fontSize="large" color={clickToSetPosition ? "primary" : "disabled"} />
        </ToggleButton>
      </Control>

      <MapboxWordmark position='bottomleft' />

      {clickToSetPosition && <Control position="bottomleft" className="leaflet-bar">
        <span className="leaflet-bar-part leaflet-bar-part-single">Kartan klikkaaminen asettaa uuden sijainnin asiakkaalle: {name}</span>
      </Control>}
    </Map>
  )
}