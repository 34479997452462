  // original: https://github.com/gregnb/mui-datatables#localization
  export const finnishTranslations = {
    body: {
      noMatch: "Haku ei tuottanut tulosta",
      toolTip: "Järjestä",
      columnHeaderTooltip: column => `Järjestä ${column.label} mukaan`
    },
    pagination: {
      next: "Seuraava sivu",
      previous: "Edellinen sivu",
      rowsPerPage: "Rivejä sivulla:",
      displayRows: "/",
    },
    toolbar: {
      search: "Haku",
      downloadCsv: "Lataa tiedostona",
      print: "Tulosta",
      viewColumns: "Valitse sarakkeet",
      filterTable: "Suodata",
    },
    filter: {
      all: "Kaikki",
      title: "SUODATTIMET",
      reset: "ALUSTA",
    },
    viewColumns: {
      title: "Näytä sarakkeet",
      titleAria: "Näytä/piilota taulukon sarakkeet",
    },
    selectedRows: {
      text: "rivi(ä) valittu",
      delete: "Poista",
      deleteAria: "Poista valitut rivit",
    },
  }
