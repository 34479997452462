import React from "react";
import { Grid, Paper, Typography } from "@material-ui/core";
import classnames from "classnames";

// styles
import useStyles from "./styles";

// logo
import logo from "../../images/logo.png";

// app name
import AppName from "../../components/AppName";

export default function Help() {
  var classes = useStyles();

  return (
    <Grid container className={classes.container}>
      <div className={classes.logotype}>
        <img className={classes.logotypeIcon} src={logo} alt="logo" />
        <Typography variant="h3" color="white" className={classes.logotypeText}>
          <AppName />
        </Typography>
      </div>
      <Paper classes={{ root: classes.paperRoot }}>
        <Typography
          variant="h1"
          color="primary"
          className={classnames(classes.textRow, classes.errorCode)}
        >
          Help
        </Typography>
        <Typography variant="h5" color="primary" className={classes.textRow}>
          Tämä sovellus on näkymä tietoihin. Sovelluksen kautta voi hallita asiakastietoja, lähettää viestejä sekä hallita omaisuutta. Karttanäkymä näyttää tiedot kartalla, josta tietoihin pääsee kiinni.
        </Typography>
        <Typography
          variant="h6"
          color="text"
          colorBrightness="secondary"
          className={classnames(classes.textRow, classes.safetyText)}
        >
          Näkyvien tietojen ja toimintojen määrä riippuu tehdystä sopimuksesta.
        </Typography>
      </Paper>
    </Grid>
  );
}
