import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Button, LinearProgress, Divider } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import Paper from "@material-ui/core/Paper";
import Box from '@material-ui/core/Box';
import { boxMargin } from './defaults';


// use Formik for form state management, https://jaredpalmer.com/formik/
// use Yup for model validation, https://www.npmjs.com/package/yup


const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(5)}px ${theme.spacing(5)}px ${theme.spacing(5)}px`,
    margin: `${theme.spacing(5)}px ${theme.spacing(5)}px ${theme.spacing(5)}px`,
  },
  box: {
    // width: "80vw"
  },
  editForm: {
    width: "80vw"
  }  
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DeleteDialog(props) {
  const classes = useStyles();
  const { open, setOpen, onConfirm, selected } = props;

  console.log('Delete dialog load');

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              <span>Poista tunniste?</span>
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
              Sulje poistamatta
            </Button>
          </Toolbar>
        </AppBar>

        <Paper elevation={1} className={classes.paper}>
          <Formik
            initialValues={selected}
            onSubmit={(values, { isSubmitting, setSubmitting }) => {
              if (isSubmitting) {
                return;
              }
              setSubmitting(false);
              setOpen(false);
              onConfirm(selected.id, selected.tid);
            }}
          >
            {({ submitForm, isSubmitting, values }) => (
              <Form className={classes.editForm}>
                <Box className={classes.box} margin={boxMargin}>
                  <Field type="text" name="tid" label="Id" component={TextField} fullWidth disabled />
                </Box>
                <Box className={classes.box} margin={boxMargin}>
                  <Field type="text" name="dn" label="Tunnisteen nimi" component={TextField} fullWidth disabled />
                </Box>
                <Box className={classes.box} margin={boxMargin}>
                  <Field type="text" name="role" label="Käyttötarkoitus" component={TextField} fullWidth disabled />
                </Box>
                <Box className={classes.box} margin={boxMargin}>
                  <Field type="text" name="info" label="Tietoja" component={TextField} fullWidth multiline disabled />
                </Box>

                <Divider />
                
                {isSubmitting && <LinearProgress />}

                <Box className={classes.box} margin={boxMargin}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={isSubmitting}
                  >
                    Poista
              </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Paper>
      </Dialog>
    </div>
  );
}
