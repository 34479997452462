import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Button, LinearProgress, FormLabel, Divider } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { Formik, Form, Field, FieldArray, ErrorMessage } from 'formik';
import { TextField, Checkbox, CheckboxWithLabel } from 'formik-material-ui';
import MuiTextField from '@material-ui/core/TextField'
import Paper from "@material-ui/core/Paper";
import * as Yup from "yup"
import Box from '@material-ui/core/Box';
import { Autocomplete } from 'formik-material-ui-lab';
import { boxMargin, phoneRegExp } from './defaults';
import LocationSelectMap from '../../../components/Map/LocationSelectMap'
import moment from 'moment'
import DeleteIcon from "@material-ui/icons/Delete";

// use Formik for form state management, https://jaredpalmer.com/formik/
// use Yup for model validation, https://www.npmjs.com/package/yup


const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(5)}px ${theme.spacing(5)}px ${theme.spacing(5)}px`,
    margin: `${theme.spacing(5)}px ${theme.spacing(5)}px ${theme.spacing(5)}px`,
  },
  box: {
    // width: "80vw"
  },
  editForm: {
    width: "80vw"
  },
  adjacentField: {
    marginRight: "5px"
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function EditDialog(props) {
  const classes = useStyles();
  const { open, setOpen, onSave, selectedCustomer, allTags } = props;

  console.log('EditDialog load');
  console.log('selectedCustomer: ', selectedCustomer);
  // console.log('allTags: ', allTags);

  const handleClose = () => {
    setOpen(false);
  };

  const validationSchema = Yup.object({
    name: Yup.string("Syötä nimi")
      .required("Nimi täytyy olla"),
    email: Yup.string("Syötä sähköpostiosoite")
      .email("Sähköpostiosoitteen täytyy olla kelvollinen osoite"),
    mobile: Yup.string("Syötä puhelinnumero")
      .matches(phoneRegExp, "Puhelinnumero ei ole kelvollinen")
  });

  return (
    <div>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              <span>{selectedCustomer.cid} - {selectedCustomer.name}</span>
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
              Sulje tallentamatta
            </Button>
          </Toolbar>
        </AppBar>

        {// (edit form modelled after, https://dev.to/finallynero/react-form-using-formik-material-ui-and-yup-2e8h) -> did not work very well...
        }
        {/* <Paper elevation={1} className={classes.paper}>
           <Formik
             render={props => <CustomerEditForm {...props} />}
             initialValues={selectedCustomer}
             validationSchema={validationSchema}
             onSubmit={(values, { setSubmitting }) => {

              setSubmitting(false);
              // alert(JSON.stringify(values, null, 2));
              setOpen(false);
              onSave(selectedCustomer.id, values);
  
            }}
           />
         </Paper> */}

        <Paper elevation={1} className={classes.paper}>
          <Formik
            initialValues={{...selectedCustomer, createBackup: false}}
            validationSchema={validationSchema}
            onSubmit={(values, { isSubmitting, setSubmitting }) => {
              if (isSubmitting) {
                return;
              }
              setSubmitting(false);
              setOpen(false);
              const updatedValues = {...values};
              const createBackup = updatedValues.createBackup;
              delete updatedValues.createBackup;
              onSave(selectedCustomer.id, selectedCustomer.cid, updatedValues, createBackup);
            }}
          >
            {({ submitForm, isSubmitting, touched, errors, values, setFieldValue }) => (
              <Form className={classes.editForm}>
                <Box className={classes.box} margin={boxMargin}>
                  <Field type="text" name="cid" label="Asiakas id" component={TextField} fullWidth disabled />
                </Box>
                <Box className={classes.box} margin={boxMargin}>
                  <Field type="text" name="contractNumber" label="Sopimusnumero" component={TextField} fullWidth />
                </Box>
                <Box className={classes.box} margin={boxMargin}>
                  <Field type="text" name="name" label="Nimi" component={TextField} fullWidth />
                </Box>
                <Box className={classes.box} margin={boxMargin}>
                  <Field type="email" name="email" label="Sähköposti" component={TextField} fullWidth />
                  <ErrorMessage name="email" component="div" />
                </Box>
                <Box className={classes.box} margin={boxMargin}>
                  <Field type="tel" name="mobile" label="Puhelin" component={TextField} fullWidth />
                  <ErrorMessage name="mobile" component="div" />
                </Box>
                <Box className={classes.box} margin={boxMargin}>
                  <Field type="text" name="info" label="Tietoja" component={TextField} fullWidth multiline />
                </Box>

                <Divider />
                <Box className={classes.box} margin={boxMargin}>
                  <FormLabel>Katuosoite</FormLabel>
                </Box>
                <Box className={classes.box} margin={boxMargin + 2}>
                  <Field type="text" name="address.line1" label="Katusoite" component={TextField} fullWidth />
                  <Field type="text" name="address.line2" label="Osoiterivi 2" component={TextField} fullWidth />
                  <Field type="text" name="address.postalCode" label="Postinumero" component={TextField} fullWidth />
                  <Field type="text" name="address.postalAddress" label="Postiosoite" component={TextField} fullWidth />
                  <Field type="text" name="address.country" label="Maa" component={TextField} fullWidth />
                </Box>

                <Divider />

                <Box className={classes.box} margin={boxMargin}>
                  <FormLabel>Laskutusosoite</FormLabel>
                </Box>
                <Box className={classes.box} margin={boxMargin}>
                  <Field type="checkbox" name="billingAddress.sameAsAddress" component={CheckboxWithLabel} Label={{ label: 'Käytä katuosoitetta' }} />
                </Box>
                <Box className={classes.box} margin={boxMargin + 2} hidden={values.billingAddress?.sameAsAddress ?? false}>
                  <Field type="text" name="billingAddress.line1" label="Osoiterivi 1" component={TextField} fullWidth />
                  <Field type="text" name="billingAddress.line2" label="Osoiterivi 2" component={TextField} fullWidth />
                  <Field type="text" name="billingAddress.postalCode" label="Postinumero" component={TextField} fullWidth />
                  <Field type="text" name="billingAddress.postalAddress" label="Postiosoite" component={TextField} fullWidth />
                  <Field type="text" name="billingAddress.country" label="Maa" component={TextField} fullWidth />
                </Box>

                <Divider />

                <Box className={classes.box} margin={boxMargin}>
                  <Field
                    name="tags"
                    // fullWidth
                    multiple
                    freeSolo
                    // defaultValue={selectedCustomer.tags}
                    component={Autocomplete}
                    options={allTags}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <MuiTextField
                        {...params}
                        error={touched['tags'] && !!errors['tags']}
                        helperText={touched['tags'] && errors['tags']}
                        label="Tunnisteet"
                        variant="outlined"
                        placeholder="tunnisteet"
                      />
                    )}
                  />
                </Box>

                <Divider />
                <Box className={classes.box} margin={boxMargin}>
                  <FormLabel>Sijainti kartalla</FormLabel>
                </Box>
                <Box className={classes.box} margin={boxMargin + 2}>

                  <Field className={classes.adjacentField} type="number" name="location[0].lat" label="Leveysaste (Latitude)" component={TextField} />
                  <Field className={classes.adjacentField} type="number" name="location[0].lng" label="Pituusaste (Longitude)" component={TextField} />
                  <Box className={classes.box}>
                    <span className='text-muted'>Sijainti merkitään Decimal Degree (DD) muodossa. Esimerkiksi Kuopion sijainti on lat: 62.89238, lng: 27.67703</span>
                  </Box>
                  <LocationSelectMap location={values.location[0]} name={values.name} onPositionChange={(e) => {
                    console.log('handling new position', e)
                    if (e) {
                      setFieldValue('location[0].lat', e.lat)
                      setFieldValue('location[0].lng', e.lng)
                    }
                  }} />
                </Box>

                {isSubmitting && <LinearProgress />}

                {/* see, https://formik.org/docs/api/fieldarray */}

                <Divider />
                <Box className={classes.box} margin={boxMargin}>
                  <FormLabel>Mittarit</FormLabel>
                </Box>
                <Box className={classes.box} margin={boxMargin + 2}>
                  <FieldArray
                    name="meters"
                    render={arrayHelpers => (
                      <div>
                        {values.meters.map((meter, index) => (
                          <div key={index}>
                            <Box className={classes.box} margin={boxMargin}>
                              <Field className={classes.adjacentField} type="text" name={`meters[${index}].id`} label="Mittarin tunniste" component={TextField} />
                              <Field className={classes.adjacentField} type="checkbox" name={`meters[${index}].isPrimary`} label="Päämittari" component={Checkbox} title="Onko valittu päämittariksi" />
                              <Field className={classes.adjacentField} type="number" name={`meters[${index}].size.d`} label="Putken halkaisija (d)" component={TextField} />
                              <Field className={classes.adjacentField} type="number" name={`meters[${index}].size.flow`} label="Maksimi virtaama" component={TextField} />
                              <Field className={classes.adjacentField} type="text" name={`meters[${index}].info`} label="Lisätietoja" component={TextField} multiline />
                              <Field className={classes.adjacentField} type="date" name={`meters[${index}].installed`} label="Asennettu" component={TextField} />

                              <Button type="button" variant="text" color="secondary" title="Poista mittari" size="small"
                                onClick={() => arrayHelpers.remove(index)}
                              >
                                <DeleteIcon />
                              </Button>
                            </Box>
                            <Divider />
                          </div>
                        ))}
                        <Button type="button" variant="contained" color="secondary" title="Lisää uusi mittari"
                          onClick={() =>
                            arrayHelpers.push({
                              id: '',
                              isPrimary: false,
                              size: { d: 0, flow: 0 },
                              info: '',
                              installed: moment().format('YYYY-MM-DD')
                            })}
                        >
                          +
                        </Button>
                      </div>
                    )}
                  />
                </Box>

                <Divider />

                <Box className={classes.box} margin={boxMargin}>
                  <Field type="checkbox" name="createBackup" component={CheckboxWithLabel} Label={{ label: 'Liittymän omistaja on vaihtunut' }} />
                  <p>
                    Valitse tämä, jos liittymän omistaja on vaihtunut. 
                    Valinta tekee vanhoista tiedoista varmuuskopion ja korvaa asiakastunnukseen liittyvät tiedot uusilla tälle lomakkeelle täytetyillä tiedoilla.
                  </p>
                </Box>

                <Divider />

                <Box className={classes.box} margin={boxMargin}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={isSubmitting}
                  >
                    Tallenna
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Paper>
      </Dialog>
    </div>
  );
}
