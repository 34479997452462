import React from 'react'
import { TileLayer, LayersControl, WMSTileLayer } from 'react-leaflet'

const { BaseLayer, Overlay } = LayersControl

export default function DefaultLayers(props) {
    const { children, position } = props

    const mbAttr = '<a href="https://www.mapbox.com/about/maps/" target="_blank">© Mapbox</a> <a href="https://www.openstreetmap.org/about/" target="_blank">© OpenStreetMap</a> <a href="https://www.mapbox.com/map-feedback/#/27.67703/62.89238/10" target="_blank">Improve this map</a> Service © <a href="https://www.preventos.fi/" target="_blank">Preventos</a>' //text at the corner of the map
    const mbUrl = 'https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token=' + process.env.REACT_APP_MAPBOX_API_KEY


    return (
        <LayersControl position={position ?? 'topright'}>
            <BaseLayer checked name='Harmaa'>
                <TileLayer
                    attribution={mbAttr}
                    url={mbUrl}
                    id='mapbox.light'
                />
            </BaseLayer>
            <BaseLayer name='Katu'>
                <TileLayer
                    attribution={mbAttr}
                    url={mbUrl}
                    id='mapbox.streets'
                />
            </BaseLayer>
            <BaseLayer name='Satelliitti'>
                <TileLayer
                    attribution={mbAttr + ' <a href="https://www.maxar.com/" target="_blank">© Maxar</a>'}
                    url={mbUrl}
                    id='mapbox.streets-satellite'
                />
            </BaseLayer>
            <BaseLayer name='MML Peruskartat'>
                <TileLayer
                    attribution='&copy; <a href="http://www.maanmittauslaitos.fi/avoindata_lisenssi_versio1_20120501" target=new>Maanmittauslaitos</a>'
                    url='http://tiles.kartat.kapsi.fi/peruskartta/{z}/{x}/{y}.jpg'
                />
            </BaseLayer>

            <Overlay name='Kiinteistörajat'>
                <WMSTileLayer
                    attribution='Maanmittauslaitos'
                    layers='CP.CadastralParcel'
                    styles='CP.CadastralParcel.OutlinesOnly'
                    transparent={true}
                    format='image/png'
                    url='https://inspire-wms.maanmittauslaitos.fi/inspire-wms/CP/ows'
                />
            </Overlay>

            {/* <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url='https://{s}.tile.osm.org/{z}/{x}/{y}.png'
            />
            <TileLayer
                attribution={mbAttr}
                url={mbUrl}
                id='mapbox.streets-satellite'
            /> */}


            {children}

        </LayersControl>
    )
}