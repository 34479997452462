import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Grid } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import CustomToolbarSelect from "./components/CustomToolbarSelect";
import CustomToolbar from "./components/CustomToolbar";
import EditDialog from "./components/EditDialog";
import DeleteDialog from "./components/DeleteDialog";
import AddDialog from "./components/AddDialog";
import MessageComposeDialog from "./components/MessageComposeDialog";
import moment from 'moment';

import { finnishTranslations } from '../../components/TableHelper/finnishTranslations'

// components
import PageTitle from "../../components/PageTitle/PageTitle";
import MultilineDisplay from '../../components/MultilineDisplay/MultilineDisplay';
import Toast from "../../components/Toast/Toast";

import MapBase from "../../components/Map/MapBase";


import { useUserState, acquireToken, useRedirectFlow } from "../../context/UserContext";
import { useCustomerState } from "../../context/CustomerProvider";
import { useConfigState } from "../../context/ConfigProvider";


export default function Customers(props) {
  // to add edit etc. buttons see https://github.com/gregnb/mui-datatables/blob/master/examples/custom-action-columns/index.js

  // global
  // const userDispatch = useUserDispatch();
  const userState = useUserState();
  const { customers, editCustomer, deleteCustomer, addCustomer } = useCustomerState();
  const { providerId } = userState
  const { config, messagingApi, geoJsonApi, tagsApi } = useConfigState()
  console.log('config on Customers', config)
  const { geoJsonItems, messaging } = config
  const messagingEnabled = messaging?.length > 0
  // const [hasError, setErrors] = useState(false)
  console.log('Customers with providerId', providerId)

  const [allTags, setAllTags] = useState([]) // all unique tags on customers
  const [oneSelectedCustomer, setOneSelectedCustomer] = useState({}) // selected customer to edit or delete

  const [selectedCustomerIdsFromMap, setSelectedCustomerIdsFromMap] = useState([]) // selected customer ids from map
  const [selectedCustomers, setSelectedCustomers] = useState([]) // all selected customers (id, index) from customers table or from map

  const [geoJsonData, setGeoJsonData] = useState({}) // dictionary for all geo json data. Key is the data id, value is actual geo json data

  // customer edit dialogs
  const [editCustomerDialogOpen, setEditCustomerDialogOpen] = useState(false)
  const [addCustomerDialogOpen, setAddCustomerDialogOpen] = useState(false)
  const [deleteCustomerDialogOpen, setDeleteCustomerDialogOpen] = useState(false)
  const [messageComposeDialogOpen, setMessageComposeDialogOpen] = useState(false)
  const [toast, setToast] = useState({
    open: false,
    autoHideDuration: null,
    title: '',
    message: '',
    children: '',
    severity: 'info'
  }) // see more details: https://material-ui.com/components/snackbars/

  // const geoJsonApiUri = `${process.env.REACT_APP_GEOJSON_API_URL}/${providerId}`;
  // const tagsApiUri = `${process.env.REACT_APP_TAGS_API_URL}/${providerId}`;
  // const messagesApiUri = {
  //   sms: `${process.env.REACT_APP_MESSAGING_API_URL}/sms/${providerId}`,
  //   email: `${process.env.REACT_APP_MESSAGING_API_URL}/email/${providerId}`
  // };

  // initial data load when page is loaded
  useEffect(() => {
    console.log('initial geojson load');
    // GET: get defined geo json data
    async function fetchGeoJsonData(data) {
      const tokenResponse = await acquireToken(geoJsonApi.tokenRequest, useRedirectFlow);
      axios
        .get(`${geoJsonApi.url}/${data}`, {
          headers: { 'Authorization': `Bearer ${tokenResponse.accessToken}` }
        })
        .then(response => {
          console.log(`get geojson data ${data} promise is ready`);
          // console.log(`geojson data before adding new ${data}: `, geoJsonData);
          setGeoJsonData(prevValue => ({ ...prevValue, [data]: response.data }));
        });
    }

    if (geoJsonItems) {
      geoJsonItems.forEach(i => {
        fetchGeoJsonData(i);
      });
    }
  }, [setGeoJsonData, geoJsonItems, geoJsonApi.tokenRequest, geoJsonApi.url])

  // initial tags load
  useEffect(() => {
    // GET: get tags data
    async function fetchTagsData() {
      const tokenResponse = await acquireToken(tagsApi.tokenRequest, useRedirectFlow);
      axios
        .get(tagsApi.url, {
          headers: { 'Authorization': `Bearer ${tokenResponse.accessToken}` }
        })
        .then(response => {
          console.log('get tags data promise ready');
          populateAllTags(response.data.map(t => t.dn));
        });
    }
    fetchTagsData();
  }, [setAllTags, tagsApi.tokenRequest, tagsApi.url])

  useEffect(() => {
    populateAllTags(customers.map(t => t.tags));
  }, [customers])






  // Populate all tags state. Parameter (tagsToAppend) can be an array of tags or an array of arrays of tags.
  const populateAllTags = function (tagsToAppend) {
    if (!tagsToAppend) {
      return;
    }
    // get distingt tags
    console.log('tags to append', tagsToAppend);
    const mergedTagsToAppend = tagsToAppend.flat(1);
    console.log('merged tags', mergedTagsToAppend);
    const uniqueTagsToAppend = [...new Set(mergedTagsToAppend.filter(t => t))];
    console.log('unique tags', uniqueTagsToAppend);

    setAllTags(prevValue => {
      const tags = [...new Set(prevValue.concat(uniqueTagsToAppend).filter(t => t))].sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
      console.log('new all tags', tags);
      return tags;
    });
  }

  // PUT: save updated customer data
  function updateData(id, cid, values, createBackup) {
    // const result = editCustomer(id, cid, values, createBackup)
    editCustomer(id, cid, values, createBackup)
    setOneSelectedCustomer({})
    // console.log('tallennuksen tulos', result)
    // const message = result.success ?
    // `Asiakkaan ${values.cid}: ${values.name} tietojen päivitys onnistui`
    // : `Asiakkaan ${values.cid}: ${values.name} tietojen päivitys epäonnistui\n\nVirhe: ${result.message}`
    // setToast({ 
    //   autoHideDuration: 6000, open: true, severity: result.success ? 'success' : 'error', title: `Asiakkaan tietojen päivitys`, message: 
    //   <MultilineDisplay>{message}</MultilineDisplay>
    // })
  }

  // POST: save new customer data
  function addData(values) {
    // const result = addCustomer(values)
    addCustomer(values)
  }

  // DELETE: delete selected customer
  function deleteData(id, cid) {
    // const result = deleteCustomer(id, cid)
    deleteCustomer(id, cid)
    setOneSelectedCustomer({})
    setSelectedCustomers([])
  }

  // update selected customers when selections are done in map
  // state variable selectedCustomerIdsFromMap is expected to be an array of customer ids
  useEffect(() => {
    console.log('selected customer ids on map', selectedCustomerIdsFromMap);
    // console.log('customer state on Customers', customers);

    const customersToAddSelected = selectedCustomerIdsFromMap.reduce((r, i) => {
      const index = customers.findIndex(c => c.id === i);
      if (~index) {
        r.push({ index: index, id: customers[index].id });
      }
      return r;
    }, []);

    console.log('selected customer with data index:', customersToAddSelected);
    if (customersToAddSelected && customersToAddSelected.length > 0) {
      setSelectedCustomers(prevValue => {
        const selectedCustomersList = [...prevValue];
        customersToAddSelected.forEach((i) => {
          if (!selectedCustomersList.find(c => c.id === i.id)) {
            // append customers that are not allready selected -> only distinct customers can be selected
            selectedCustomersList.push(i);
          }
        });
        console.log('selected customers: ', selectedCustomersList);
        return selectedCustomersList
      });
    }
  }, [selectedCustomerIdsFromMap, customers]);


  // open customer dialogs
  const openEditCustomerDialog = function (dataIndex) {
    console.log('edit customer', dataIndex, customers[dataIndex]);
    setOneSelectedCustomer(customers[dataIndex]);
    setEditCustomerDialogOpen(true);
  }

  const openDeleteCustomerDialog = function (dataIndex) {
    console.log('delete customer', dataIndex, customers[dataIndex]);
    setOneSelectedCustomer(customers[dataIndex]);
    setDeleteCustomerDialogOpen(true);
  }

  const openAddCustomerDialog = function () {
    console.log('add customer');
    setAddCustomerDialogOpen(true);
  }

  const openMessageComposeDialog = function () {
    console.log('opening message compose dialog');
    setMessageComposeDialogOpen(true);
  }

  const handleSendMessage = async (recipients, message) => {
    console.log('handling send message to', recipients, message);
    // TODO: send the message!
    if (!recipients || !message) {
      console.log('no recipient or message defined, cannot send message')
      return
    }

    // compose payload and url
    let messageUrl = ''
    let payload = {}
    switch (message.method) {
      case 'email':
        messageUrl = messagingApi.urls.email
        payload = {
          subject: message.emailSubject,
          replyTo: {
            email: message.emailReplyTo
          },
          recipients: [],
          isHtml: false,
          content: message.emailMessage
        }
        for (const r of recipients) {
          const customer = customers.find(c => c.id === r.id)
          if (customer) {
            payload.recipients.push({ name: customer.name, email: customer.email })
          }
        }
        // console.log('composed email message', payload);
        break;
      case 'sms':
        messageUrl = messagingApi.urls.sms
        payload = {
          sender: messaging.find(m => m.method === 'sms')?.sender ?? '',
          msisdns: [],
          content: message.smsMessage
        };
        for (const r of recipients) {
          const customer = customers.find(c => c.id === r.id)
          if (customer) {
            payload.msisdns.push(customer.mobile)
          }
        }
        // console.log('composed sms message', payload);
        break;
      default:
        throw new Error(`Invalid message method type: ${message.type}`)
    }

    // send message
    console.log('sending message to: ', messageUrl)
    const tokenResponse = await acquireToken(messagingApi.tokenRequest, useRedirectFlow);
    axios
      .post(messageUrl,
        payload,
        {
          headers: { 'Authorization': `Bearer ${tokenResponse.accessToken}` }
        })
      .then(response => {
        const d = response.data
        console.log('message send completed', d)

        setToast({
          autoHideDuration: null, open: true, severity: 'success', title: `${d.method === 'sms' ? 'Tekstiviesti' : 'Sähköposti'} on lähetetty!`, message:
            <MultilineDisplay>{`Viestin tunnus: ${d.id}\nVastaanottajia: ${d.requestedRecipients}\nUniikkeja vastaanottajia: ${d.recipients}\nVirheellisiä vastaanottajia: ${d.recipientsWithInvalidData}\n\nVoit tarkistaa viestin tilan Viestit-osiosta hetken kuluttua.`}</MultilineDisplay>
        })
      })
      .catch(error => {
        setToast({
          autoHideDuration: null, open: true, severity: 'error', title: 'Viestin lähetyksessä tapahtui virhe. Viestiä ei ole lähetetty!', message: error.message
        })
      })
  }

  const handleToastClose = () => {
    setToast(t => {
      return { ...t, open: false }
    })
  }

  // customers table columns
  const columns = [
    {
      name: "cid",
      label: "Asiakas id",
      options: {
        filter: false
      }
    },
    {
      name: "name",
      label: "Nimi",
      options: {
        filter: false,
      }
    },
    {
      name: "email",
      label: "Sähköposti",
      options: {
        filter: false
      }
    },
    {
      name: "mobile",
      label: "Puhelin",
      options: {
        filter: false
      }
    },
    {
      name: "info",
      label: "Tietoja",
      options: {
        filter: false,
        display: 'false',
        customBodyRender: (value, tableMeta, updateValue) => (
          value && <MultilineDisplay>{value}</MultilineDisplay>
        )
      }
    },
    {
      name: "address",
      label: "Osoite",
      options: {
        filter: false,
        display: 'false',
        customBodyRender: (value, tableMeta, updateValue) => (
          value ?
            <div>
              <span>{value.line1}</span><br />
              <span>{value.line2}</span><br />
              <span>{value.postalCode} {value.postalAddress}</span>
            </div>
            : ''
        )
      }
    },
    {
      name: "location",
      label: "Sijainti",
      options: {
        filter: false,
        display: 'false',
        customBodyRender: (value, tableMeta, updateValue) => (
          (value && value.length > 0) ?
            <div>
              <span>lat: {value[0].lat}</span><br />
              <span>lng: {value[0].lng}</span>
            </div>
            : ''
        )
      }
    },
    {
      name: "tags",
      label: "Tunnisteet",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          value ?
            <span>{value.sort().join(', ')}</span>
            : ''
        )
      }
    },
  ];

  const customerIdColumnIndex = columns.map(c => c.name).indexOf('cid');

  return (
    <>
      <PageTitle title="Asiakkaat" />
      <Grid container spacing={4}>

        <Grid item xs={12}>
          <MapBase customers={customers}
            selectedCustomers={selectedCustomers}
            geoJsonData={geoJsonData}
            onCustomerSelect={setSelectedCustomerIdsFromMap}
          />
        </Grid>

        <Grid item xs={12}>
          <MUIDataTable
            // title="Asiakkaat"
            data={customers}
            columns={columns}
            options={{
              textLabels: finnishTranslations,
              filter: true,
              filterType: 'checkbox',
              responsive: 'standard',
              rowsSelected: selectedCustomers.map(c => c.index),
              selectableRows: 'multiple',
              selectToolbarPlacement: 'above',
              selectableRowsOnClick: true,
              download: true,
              downloadOptions: {
                filename: `asiakkaat-${moment().format('YYYY-MM-DD')}.csv`,
                filterOptions: {
                  useDisplayedColumnsOnly: true,
                }
              },
              onDownload: (buildHead, buildBody, columns, data) => {
                if (selectedCustomers && selectedCustomers.length > 0) {
                  // select only selected customers to be in csv
                  data = data.filter(r => selectedCustomers.some(c => c.index === r.index));
                }
                // muidatatable default csv creator added with BOM mark for Excel, see https://github.com/gregnb/mui-datatables/pull/722#issuecomment-526346440
                return "\uFEFF" + buildHead(columns) + buildBody(data);

                // tests to convert objects (customer: address, billingAddress, location, meters) to csv via papaparse -> does not yet work
                // console.log('columns to convert to csv', columns);
                // console.log('data to convert to csv', data);
                // return Papa.unparse(customers);
              },
              onRowSelectionChange: (currentRowSelected, allRowsSelected, rowsSelected) => {
                console.log('onRowsSelect', currentRowSelected, allRowsSelected, rowsSelected);
                if (allRowsSelected) {
                  setSelectedCustomers(allRowsSelected.map(i => {
                    return { id: customers[i.dataIndex].id, index: i.dataIndex }
                  }));
                } else {
                  setSelectedCustomers([]);
                }
              },
              customToolbar: () => {
                return (<CustomToolbar handleAddCustomerClick={openAddCustomerDialog} />);
              },
              customSort: (data, colIndex, order) => {
                return data.sort((a, b) => {
                  if (colIndex === customerIdColumnIndex) {
                    return (parseInt(a.data[colIndex]) < parseInt(b.data[colIndex]) ? -1 : 1) * (order === 'desc' ? 1 : -1);
                  } else {
                    return (a.data[colIndex] < b.data[colIndex] ? -1 : 1) * (order === 'desc' ? 1 : -1);
                  }
                });
              },
              customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
                <CustomToolbarSelect
                  selectedRows={selectedRows}
                  displayData={displayData}
                  setSelectedRows={setSelectedRows}
                  editCustomer={openEditCustomerDialog}
                  deleteCustomer={openDeleteCustomerDialog}
                  messagingEnabled={messagingEnabled}
                  sendMessage={openMessageComposeDialog}
                />
              ),
            }}
          />
        </Grid>
      </Grid>

      <EditDialog
        selectedCustomer={oneSelectedCustomer}
        open={editCustomerDialogOpen}
        allTags={allTags}
        setOpen={setEditCustomerDialogOpen}
        onSave={updateData}
      />
      <DeleteDialog
        selectedCustomer={oneSelectedCustomer}
        open={deleteCustomerDialogOpen}
        setOpen={setDeleteCustomerDialogOpen}
        onConfirm={deleteData}
      />
      <AddDialog
        open={addCustomerDialogOpen}
        allTags={allTags}
        setOpen={setAddCustomerDialogOpen}
        onSave={addData}
      />
      {messagingEnabled && <MessageComposeDialog
        open={messageComposeDialogOpen}
        setOpen={setMessageComposeDialogOpen}
        onSend={handleSendMessage}
        customers={customers}
        selectedCustomers={selectedCustomers}
        methods={messaging.map(m => m.method)}
      />}

      <Toast
        open={toast.open}
        severity={toast.severity}
        autoHideDuration={toast.autoHideDuration}
        onClose={handleToastClose}
        title={toast.title}
        message={toast.message}
      >
        {toast.children}
      </Toast>
    </>
  );
}
