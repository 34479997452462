import React, { useEffect } from "react";
import { Field, useField, useFormikContext } from "formik";
import { slugify } from '../../../components/SlugHelper/slugify'

// modelled after, https://github.com/formium/formik/issues/2204#issuecomment-574207100

export default function SlufigiedField (props) {
  const {
    values: { dn },
    setFieldValue
  } = useFormikContext();
  const [field, meta] = useField(props);

  useEffect(() => {
    let isCurrent = true;
    if (dn.trim() !== "") {
      const slug = slugify(dn);
      if (!!isCurrent) {
        // prevent setting old values
        setFieldValue(props.name, slug);
      }
    }
    return () => {
      isCurrent = false;
    };
  }, [dn, setFieldValue, props.name]);

  return (
    <>
      <Field {...props} {...field} />
      {!!meta.touched && !!meta.error && <div>{meta.error}</div>}
    </>
  );
};