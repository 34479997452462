import { UserAgentApplication, Logger, LogLevel } from "msal";

export const requiresInteraction = errorMessage => {
    if (!errorMessage || !errorMessage.length) {
        return false;
    }

    return (
        errorMessage.indexOf("consent_required") > -1 ||
        errorMessage.indexOf("interaction_required") > -1 ||
        errorMessage.indexOf("login_required") > -1
    );
};

export const fetchMsGraph = async (url, accessToken) => {
    const response = await fetch(url, {
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    });

    return response.json();
};

export const isIE = () => {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf("MSIE ") > -1;
    const msie11 = ua.indexOf("Trident/") > -1;

    // If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
    // const isEdge = ua.indexOf("Edge/") > -1;

    return msie || msie11;
};

export const GRAPH_SCOPES = {
    // graph does not seem to work on Azure AD B2C ... tested on 4.8.2020 with msal 1.3.3
    OPENID: "https://graph.microsoft.com/openid",
    PROFILE: "https://graph.microsoft.com/profile",
    USER_READ: "https://graph.microsoft.com/User.Read",

    // OPENID: "openid",
    // PROFILE: "profile",
    MAIL_READ: "Mail.Read"
};

export const GRAPH_ENDPOINTS = {
    ME: "https://graph.microsoft.com/v1.0/me",
    MAIL: "https://graph.microsoft.com/v1.0/me/messages"
};

export const GRAPH_REQUESTS = {
    LOGIN: {
        scopes: [
            GRAPH_SCOPES.OPENID,
            GRAPH_SCOPES.PROFILE,
            GRAPH_SCOPES.USER_READ
        ]
    },
    EMAIL: {
        scopes: [GRAPH_SCOPES.MAIL_READ]
    }
};

const authority = `https://${process.env.REACT_APP_MSAL_B2CTENANT}.b2clogin.com/${process.env.REACT_APP_MSAL_B2CTENANT}.onmicrosoft.com/${process.env.REACT_APP_MSAL_REDIRECT_POLICY}`; //"https://login.microsoftonline.com/common",
const redirectUri = `${window.location.origin}`; //`${window.location.origin}/hero` //"http://localhost:3000/hero", //window.location.origin,
const clientId = process.env.REACT_APP_MSAL_B2CAPPID;


const isDevelopment = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? true : false;

if (isDevelopment) {
    console.log('authority', authority)
    console.log('redirectUri', redirectUri)
    console.log('clientId', clientId)
}

export const msalApp = new UserAgentApplication({
    auth: {
        clientId: clientId,
        authority: authority,
        validateAuthority: false,
        postLogoutRedirectUri: window.location.origin, // "http://localhost:3000",
        redirectUri: redirectUri, //"http://localhost:3000/hero", //window.location.origin,
        navigateToLoginRequestUrl: false
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: isIE()
    },
    system: {
        logger: new Logger(
            (logLevel, message, containsPii) => {
                console.log("[MSAL]", message);
            },
            {
                level: isDevelopment ? LogLevel.Verbose : LogLevel.Warning,
                piiLoggingEnabled: false
            }
        )
    },
});

