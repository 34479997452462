import React from "react";

// styles
import useStyles from "./styles";

export default function PageTitle(props) {
  var classes = useStyles();
  const { children } = props

  return (
    <div className={classes.multilineDisplay}>
      {children}
    </div>
  );
}
