import React from 'react';
import { Marker } from 'react-leaflet'

export default function OpenMarker(props) {
    const initMarker = ref => {
        if (ref) {
            ref.leafletElement.openPopup()
        }
    }

    return <Marker ref={initMarker} {...props} />
}