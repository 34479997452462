import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

// colors 
import red from '@material-ui/core/colors/red';
import deepOrange from '@material-ui/core/colors/deepOrange';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import blueGrey from '@material-ui/core/colors/blueGrey';

const useStyles = makeStyles((theme) => ({
  legend: {
    maxWidth: 400,
    margin: theme.spacing(1)
  },
  bounce: {
    backgroundColor: deepOrange[500]
  },
  deferred: {
    backgroundColor: amber[700]
  },
  delivered: {
    backgroundColor: green[400]
  },
  enroute: {
    backgroundColor: amber[200]
  },
  expired: {
    backgroundColor: red[500]
  },
  open: {
    backgroundColor: green['A400']
  },
  processed: {
    backgroundColor: blueGrey[200]
  },
  rejected: {
    backgroundColor: red[700]
  },
  undeliverable: {
    backgroundColor: deepOrange[500]
  }
}));

export default function Legend(props) {
  const classes = useStyles();
  const { status, legend } = props;

  const renderStatusOnly = status && !legend;

  return (
    <>
      {renderStatusOnly && <Typography className={classes[status.toLowerCase()]} gutterBottom variant="h6" component="h2">
        {status.toLowerCase()}
      </Typography>}

      {!renderStatusOnly && <Card className={classes.legend}>
        <CardContent>
          {status && <Typography className={classes[status.toLowerCase()]} gutterBottom variant="h6" component="h2">
            {status.toLowerCase()}
          </Typography>}
          {legend && <Typography variant="body2" color="textSecondary" component="p">
            {legend}
          </Typography>}
        </CardContent>
      </Card>}
    </>
  );
}
