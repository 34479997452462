import React, { useState, useEffect } from "react"
import { withLeaflet } from "react-leaflet"
import Control from "react-leaflet-control"
import GpsFixed from "@material-ui/icons/GpsFixed"
import GpsNotFixed from "@material-ui/icons/GpsNotFixed"
import GpsOff from "@material-ui/icons/GpsOff"
import Badge from '@material-ui/core/Badge'

function LocationInfo(props) {
  const { position, locationStatus } = props

  const [icon, setIcon] = useState()

  useEffect(() => {
    setIcon(getIcon(''))
    console.log('location info status is GpsOff')
  }, [])

  useEffect(() => {
    setIcon(getIcon(locationStatus))
  }, [locationStatus])

  const getIcon = (state) => {
    if (state.accuracy) {
      if (state.accuracy < 50) {
        console.log('location info status is GpsFixed')
        return (<div className='leaflet-bar-part leaflet-bar-part-single' title={`Paikannustarkkuus on hyvä ${state.accuracy} m`}>
          <Badge color="primary" badgeContent={state.accuracy}>
            <GpsFixed />
          </Badge>
          {/* <span>{`Paikannustarkkuus on hyvä ${state.accuracy} m`}</span> */}
        </div>)
      }
      console.log('location info status is GpsNotFixed')
      return (<div className='leaflet-bar-part leaflet-bar-part-single' title={`Paikannustarkkuus on heikko (${state.accuracy} m)`}>
        <Badge color="error" badgeContent={state.accuracy}>
          <GpsNotFixed />
        </Badge>
        {/* <span>{`Paikannustarkkuus on heikko (${state.accuracy} m)`}</span> */}
      </div>)
    }
    console.log('location info status is GpsOff')
    return <div className='leaflet-bar-part leaflet-bar-part-single' title={`Paikannus ei ole päällä`}><GpsOff /></div>
  }

  return (
    <Control position={position} className="leaflet-bar">
      {icon}
    </Control>
  )
}

export default withLeaflet(LocationInfo)
